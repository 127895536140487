import "bootstrap/dist/css/bootstrap.min.css";
import "./HomePage.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from "react-bootstrap/Button";
import AgreementModal from "../../components/modals/agreementModal/AgreementModal.jsx";
import LoginForm from "../../components/forms/loginForm/LoginForm.jsx";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal.jsx";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import logo from "../../assets/tree-logo.png";
import { SERVER_HOST } from "../../env.js";
import {getUserType,getAccessToken} from "../utility/utility.js";
import { updateState } from "../../store/storageSlice.js";

const host = SERVER_HOST;

function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [agreementModalShow, setAgreementModalShow] = useState(false)
  const [activeTab, setActiveTab] = useState("patient");
  const [notify, setNotify] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [timer, setTimer] = useState(60);
  const [showSubmitOTP, setShowSubmitOTP] = useState(true);
  const [otp, setOtp] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [isNotifyError, setIsNotifyError] = useState(false);
  const [email,  setEmail] = useState("");
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    localStorage.clear();
    document.cookie = "accessToken=;";
    setShowLoginForm(true);
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setNotify(null);
    setShowNotifyModal(false);
  };

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };

  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const handleForm = async (event) => {
    try {
      event.preventDefault();
      const form = event.target;
      const enteredUsername = form.username.value.trim();
      const enteredPassword = form.password.value.trim();
      dispatch(updateState({field : "userName", value : enteredUsername}));
      dispatch(updateState({field : "password", value : enteredPassword}));

      setSpin(true);

      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        if (activeTab === "patient") {
          event.preventDefault(); // Prevent form submission
          dispatch(updateState({field : "userId",value : enteredUsername}))
          dispatch(updateState({field : "password", value: enteredPassword}))
          setAgreementModalShow(true);
          setSpin(false);
        } 
        else {
          event.preventDefault();
          try {
            const formData = new FormData();
            dispatch(updateState({field : "userId", value : enteredUsername}))
            formData.append("user_id", enteredUsername);
            formData.append("password", enteredPassword);

          const response = await axios.post(`${host}/login`, formData);

          const responseData = response.data;

            if (responseData.success) {
              setSpin(false);
              setIsNotifyError(false);
              handleApiNotify("OTP sent successfully");
              setShowSubmitOTP(true);
              startOtpTimer();
              setEmail(responseData.user_email);
              document.cookie = `accessToken=${responseData.access_token}; path=/;`;
              setShowLoginForm(false);
            } else {
              setIsNotifyError(true);
              handleApiNotify(responseData.message);
              console.error("Login failed");
            }
          } catch (error) {
            setSpin(false);
            setIsNotifyError(true);
            handleApiNotify("Login failed");
            console.error("Error:", error);
          }
        }
      }
    } finally {
      setSpin(false);
    }
  };

  const startOtpTimer = () => {
    let intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setShowSubmitOTP(false);
          return 60; // reset timer
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get(`${host}/verify_otp`, {
        params: {
          otp: otp.trim(),
          email_id: email,
        },
      });

      if (response.data.success) {
        handleApiNotify("Login successful");
        setIsNotifyError(false);

        const token = getAccessToken();
        const userType = getUserType(token);

        if (userType === "doctor") {
          navigate("/patient");
        } else if (userType === "admin") {
          navigate("/allpatients");
        } else {
          console.error("Invalid user type");
        }
      } else {
        setIsNotifyError(true);
        setShowLoginForm(true);
        handleApiNotify("OTP verification failed");
        console.error("OTP verification failed:", response.data.message);
      }
    } catch (error) {
      setIsNotifyError(true);
      handleApiNotify("OTP verification failed");
      console.error("Error:", error);
    }
  };

  return (
    <div className="home-page-container">
      <header className="home-page-heading">
        <h1>GeneConnect</h1>
      </header>
      <section>
        {/* Image beside form */}
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-sm-6 col-6 d-flex justify-content-center ">
              <img src={logo} className="img-fluid" alt="tree"></img>
            </div>
            <div className="col-sm-8 col-md-8 col-xl-4 sm-me-5 d-flex flex-column">
              <div className={showLoginForm ? "homepage-login-show" : "homepage-login-hide"}>
                <div className="container-fluid h-custom">
                  <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-md-8 col-xl-12">
                      <Tabs
                        id="user-role-tabs"
                        activeKey={activeTab}
                        onSelect={(key) => handleTabChange(key)}
                        className="mb-3 login-tabs"
                      >
                        <Tab eventKey="patient" title="Patient">
                          <LoginForm formSubmit={handleForm} spin={spin}/>
                        </Tab>

                        <Tab eventKey="doctor" title="Doctor">
                          <LoginForm formSubmit={handleForm} spin={spin} />
                        </Tab>

                        <Tab eventKey="admin" title="Admin">
                          <LoginForm formSubmit={handleForm} spin={spin} />
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
              <div className={!showLoginForm ? "homepage-login-show" : "homepage-login-hide"}>
                <Form onSubmit={handleOtpSubmit}>
                  <Row className="home-otp-form-div mb-3">
                    <Form.Group as={Col} controlId="validationCustom01">
                      <Form.Control
                        name="otp"
                        required
                        type="text"
                        placeholder="Enter otp"
                        onChange={(e) => setOtp(e.target.value)}
                        title="OTP"
                        defaultValue=""
                        className="otp-input-field"
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                      {showSubmitOTP && (
                        <small className="home-otp-timer">
                          {Math.floor(timer / 60)}:{timer % 60}s remaining
                        </small>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <Col className="d-flex justify-content-center">
                      {showSubmitOTP && (
                        <Button type="submit" className="home-otp-submit-btn">
                          Submit
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      {!showSubmitOTP && (
                        <Button
                          className="register-otp-submit-btn"
                          onClick={handleForm}
                          disabled={spin}
                        >
                          {spin ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Resend OTP"
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AgreementModal
        show={agreementModalShow}
        onHide={() => setAgreementModalShow(false)}
      />
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        isLoading={false}
        notifyError={isNotifyError}
      />
    </div>
  );
}

export default HomePage;
