import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  addSibling as addProbandFullSibling,
  setSubmitted as setProbandFullSiblingSubmitted,
  setIsCollapsed as setIsProbandFullSiblingCollapsed,
  setIsEditMode as setEditModeInProbandFullSibling,
  setIsSkipped as setProbandFullSiblingSkipped,
  resetForm as resetProbandFullSiblingForm,
  setIsResumed as setIsProbandFullSiblingResumed,
} from "../../../store/probandFullSiblingFormSlice";
import {
  addSibling as addProbandHalfSibling,
  setSubmitted as setProbandHalfSiblingSubmitted,
  setIsCollapsed as setIsProbandHalfSiblingCollapsed,
  setIsEditMode as setEditModeInProbandHalfSibling,
  setIsSkipped as setProbandHalfSiblingSkipped,
  resetForm as resetProbandHalfSiblingForm,
  setIsResumed as setIsProbandHalfSiblingResumed,
} from "../../../store/probandHalfSiblingFormSlice";
import {
  addSibling as addFatherFullSibling,
  setSubmitted as setFatherFullSiblingSubmitted,
  setIsCollapsed as setIsFatherFullSiblingCollapsed,
  setIsEditMode as setEditModeInFatherFullSibling,
  setIsSkipped as setFatherFullSiblingSkipped,
  resetForm as resetFatherFullSiblingForm,
  setIsResumed as setIsFatherFullSiblingResumed,
} from "../../../store/fatherFullSiblingFormSlice";
import {
  addSibling as addFatherHalfSibling,
  setSubmitted as setFatherHalfSiblingSubmitted,
  setIsCollapsed as setIsFatherHalfSiblingCollapsed,
  setIsEditMode as setEditModeInFatherHalfSibling,
  setIsSkipped as setFatherHalfSiblingSkipped,
  resetForm as resetFatherHalfSiblingForm,
  setIsResumed as setIsFatherHalfSiblingResumed,
} from "../../../store/fatherHalfSiblingFormSlice";
import {
  addSibling as addMotherFullSibling,
  setSubmitted as setMotherFullSiblingSubmitted,
  setIsCollapsed as setIsMotherFullSiblingCollapsed,
  setIsEditMode as setEditModeInMotherFullSibling,
  setIsSkipped as setMotherFullSiblingSkipped,
  resetForm as resetMotherFullSiblingForm,
  setIsResumed as setIsMotherFullSiblingResumed,
} from "../../../store/motherFullSiblingFormSlice";
import {
  addSibling as addMotherHalfSibling,
  setSubmitted as setMotherHalfSiblingSubmitted,
  setIsCollapsed as setIsMotherHalfSiblingCollapsed,
  setIsEditMode as setEditModeInMotherHalfSibling,
  setIsSkipped as setMotherHalfSiblingSkipped,
  resetForm as resetMotherHalfSiblingForm,
  setIsResumed as setIsMotherHalfSiblingResumed,
  toggleAllChildrenCollapse as toggleAllMotherHalfSiblingChildren,
} from "../../../store/motherHalfSiblingFormSlice";
import { toggleAllChildrenCollapse as toggleAllMotherFullSiblingChildren } from "../../../store/motherFullSiblingFormSlice";
import { toggleAllChildrenCollapse as toggleAllFatherHalfSiblingChildren } from "../../../store/fatherHalfSiblingFormSlice";
import { toggleAllChildrenCollapse as toggleAllFatherFullSiblingChildren } from "../../../store/fatherFullSiblingFormSlice";
import { toggleAllChildrenCollapse as toggleAllProbandFullSiblingChildren } from "../../../store/probandFullSiblingFormSlice";
import { toggleAllChildrenCollapse as toggleAllProbandHalfSiblingChildren } from "../../../store/probandHalfSiblingFormSlice";
import { setIsCollapsed as setIsProbandPartnerCollapsed } from "../../../store/probandPartnerFormSlice";
import { setIsCollapsed as setIsPaternalGrandParentCollapsed } from "../../../store/paternalGrandParentFormSlice";
import { validateForm, adjustLayout, getMaxNodeCount, scrollToElement } from "../../../pages/utility/utility";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import Sibling from "./Sibling";
import {CustomAlert, UpdateAlert} from "../../customAlert/CustomAlert";
import {setAllSiblingsCollapsed as setPFSCollapsed } from "../../../store/probandFullSiblingFormSlice";
import {setAllSiblingsCollapsed as setPHSCollapsed } from "../../../store/probandHalfSiblingFormSlice";
import {setAllSiblingsCollapsed as setFFSCollapsed } from "../../../store/fatherFullSiblingFormSlice";
import {setAllSiblingsCollapsed as setFHSCollapsed } from "../../../store/fatherHalfSiblingFormSlice";
import {setAllSiblingsCollapsed as setMFSCollapsed } from "../../../store/motherFullSiblingFormSlice";
import {setAllSiblingsCollapsed as setMHSCollapsed } from "../../../store/motherHalfSiblingFormSlice";
import _ from 'lodash';
import useEditModeFormNode from "../../../hooks/useEditModeFormNode";
const SiblingForm = ({ sendFormData, handleCancel, autoSaveFormData, nodeRelation, collapseAllExcept, savedChoice, currentChoice, formEditStatus }) => {
  const [errors, setErrors] = useState([]);
  const probandFullSiblings = useSelector((state) => state.probandFullSiblingForm.siblings);
  const probandHalfSiblings = useSelector((state) => state.probandHalfSiblingForm.siblings);
  const fatherFullSiblings = useSelector((state) => state.fatherFullSiblingForm.siblings);
  const fatherHalfSiblings = useSelector((state) => state.fatherHalfSiblingForm.siblings);
  const motherFullSiblings = useSelector((state) => state.motherFullSiblingForm.siblings);
  const motherHalfSiblings = useSelector((state) => state.motherHalfSiblingForm.siblings);

  const isProbandFullSiblingSubmitted = useSelector((state) => state.probandFullSiblingForm.isSubmitted);
  const isProbandHalfSiblingSubmitted = useSelector((state) => state.probandHalfSiblingForm.isSubmitted);
  const isFatherFullSiblingSubmitted = useSelector((state) => state.fatherFullSiblingForm.isSubmitted);
  const isFatherHalfSiblingSubmitted = useSelector((state) => state.fatherHalfSiblingForm.isSubmitted);
  const isMotherFullSiblingSubmitted = useSelector((state) => state.motherFullSiblingForm.isSubmitted);
  const isMotherHalfSiblingSubmitted = useSelector((state) => state.motherHalfSiblingForm.isSubmitted);

  const isEditModeInProbandFullSibling = useSelector((state) => state.probandFullSiblingForm.isEditMode);
  const isEditModeInProbandHalfSibling = useSelector((state) => state.probandHalfSiblingForm.isEditMode);
  const isEditModeInFatherFullSibling = useSelector((state) => state.fatherFullSiblingForm.isEditMode);
  const isEditModeInFatherHalfSibling = useSelector((state) => state.fatherHalfSiblingForm.isEditMode);
  const isEditModeInMotherFullSibling = useSelector((state) => state.motherFullSiblingForm.isEditMode);
  const isEditModeInMotherHalfSibling = useSelector((state) => state.motherHalfSiblingForm.isEditMode);

  const isProbandFullSiblingResumed = useSelector((state) => state.probandFullSiblingForm.isResumed);
  const isProbandHalfSiblingResumed = useSelector((state) => state.probandHalfSiblingForm.isResumed);
  const isFatherFullSiblingResumed = useSelector((state) => state.fatherFullSiblingForm.isResumed);
  const isFatherHalfSiblingResumed = useSelector((state) => state.fatherHalfSiblingForm.isResumed);
  const isMotherFullSiblingResumed = useSelector((state) => state.motherFullSiblingForm.isResumed);
  const isMotherHalfSiblingResumed = useSelector((state) => state.motherHalfSiblingForm.isResumed);

  const isProbandFullSiblingCollapsed = useSelector((state) => state.probandFullSiblingForm.isCollapsed);
  const isProbandHalfSiblingCollapsed = useSelector((state) => state.probandHalfSiblingForm.isCollapsed);
  const isFatherFullSiblingCollapsed = useSelector((state) => state.fatherFullSiblingForm.isCollapsed);
  const isFatherHalfSiblingCollapsed = useSelector((state) => state.fatherHalfSiblingForm.isCollapsed);
  const isMotherFullSiblingCollapsed = useSelector((state) => state.motherFullSiblingForm.isCollapsed);
  const isMotherHalfSiblingCollapsed = useSelector((state) => state.motherHalfSiblingForm.isCollapsed);

  const isProbandFullSiblingSkipped = useSelector((state) => state.probandFullSiblingForm.isSkipped);
  const isProbandHalfSiblingSkipped = useSelector((state) => state.probandHalfSiblingForm.isSkipped);
  const isFatherFullSiblingSkipped = useSelector((state) => state.fatherFullSiblingForm.isSkipped);
  const isFatherHalfSiblingSkipped = useSelector((state) => state.fatherHalfSiblingForm.isSkipped);
  const isMotherFullSiblingSkipped = useSelector((state) => state.motherFullSiblingForm.isSkipped);
  const isMotherHalfSiblingSkipped = useSelector((state) => state.motherHalfSiblingForm.isSkipped);

  const { conversationTone } = useSelector((state) => state.storage);
  const dispatch = useDispatch();
  const currentlyEditingFormId = useEditModeFormNode();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [showSaveChangesAlert, setShowSaveChangesAlert] = useState(false);
  const [showFillDetailsAlert, setShowFillDetailsAlert] = useState(false);
  const [showSiblingLimitAlert, setShowSiblingLimitAlert] = useState(false);

  const nodeRelationsWithFullSiblings = ["PS", "PFS", "PMS"];
  const generalSiblingForm = (siblingData) => {
    return siblingData.map((sibling) => ({
      siblingAge: sibling.age,
      childrenAges: sibling.children.map((child) => child.age),
    }));
  }

  const getIdentity = () => {
    if (nodeRelation === "PS" || nodeRelation === "PHS") {
      return "Proband";
    }
    if (nodeRelation === "PFS" || nodeRelation === "PFHS") {
      return "Father";
    }
    if (nodeRelation === "PMS" || nodeRelation === "PMHS") {
      return "Mother";
    }
  };

  const identity = getIdentity();
  const maxSiblingCount = getMaxNodeCount();
  const [spouseAlert, setSpouseAlert] = useState(false);

  const getSiblingsData = () => {
    switch (nodeRelation) {
      case "PS":
        return {
          siblings: probandFullSiblings,
          isSubmitted: isProbandFullSiblingSubmitted,
          isSkipped: isProbandFullSiblingSkipped,
          isCollapsed: isProbandFullSiblingCollapsed,
          isEditMode: isEditModeInProbandFullSibling,
          isResumed: isProbandFullSiblingResumed,
        };
      case "PHS":
        return {
          siblings: probandHalfSiblings,
          isSubmitted: isProbandHalfSiblingSubmitted,
          isSkipped: isProbandHalfSiblingSkipped,
          isCollapsed: isProbandHalfSiblingCollapsed,
          isEditMode: isEditModeInProbandHalfSibling,
          isResumed: isProbandHalfSiblingResumed,
        };
      case "PFS":
        return {
          siblings: fatherFullSiblings,
          isSubmitted: isFatherFullSiblingSubmitted,
          isSkipped: isFatherFullSiblingSkipped,
          isCollapsed: isFatherFullSiblingCollapsed,
          isEditMode: isEditModeInFatherFullSibling,
          isResumed: isFatherFullSiblingResumed,
        };
      case "PFHS":
        return {
          siblings: fatherHalfSiblings,
          isSubmitted: isFatherHalfSiblingSubmitted,
          isSkipped: isFatherHalfSiblingSkipped,
          isCollapsed: isFatherHalfSiblingCollapsed,
          isEditMode: isEditModeInFatherHalfSibling,
          isResumed: isFatherHalfSiblingResumed,
        };
      case "PMS":
        return {
          siblings: motherFullSiblings,
          isSubmitted: isMotherFullSiblingSubmitted,
          isSkipped: isMotherFullSiblingSkipped,
          isCollapsed: isMotherFullSiblingCollapsed,
          isEditMode: isEditModeInMotherFullSibling,
          isResumed: isMotherFullSiblingResumed,
        };
      case "PMHS":
        return {
          siblings: motherHalfSiblings,
          isSubmitted: isMotherHalfSiblingSubmitted,
          isSkipped: isMotherHalfSiblingSkipped,
          isCollapsed: isMotherHalfSiblingCollapsed,
          isEditMode: isEditModeInMotherHalfSibling,
          isResumed: isMotherHalfSiblingResumed,
        };
      default:
        return { siblings: null, isSubmitted: null, isSkipped: null, isCollapsed: null, isEditMode: null, isResumed: null };
    }
  };

  const { siblings, isSubmitted, isSkipped, isCollapsed, isEditMode, isResumed } = getSiblingsData();
  const alertTitle = `Are you sure you want to ${isEditMode ? "cancel?" : "skip?"}`
  const alertMessage = isEditMode ? "Unsaved data may be lost." : "Don’t worry, you can anytime open this section and fill the details.";
  const [isModified, setIsModified] = useState(false);
  const [initialFormState, setInitialFormState] = useState(siblings);
  const [siblingCount, setSiblingCount] = useState(siblings.length);
  const isEditModeInAnySibling =
    isEditModeInProbandFullSibling ||
    isEditModeInProbandHalfSibling ||
    isEditModeInFatherFullSibling ||
    isEditModeInFatherHalfSibling ||
    isEditModeInMotherFullSibling ||
    isEditModeInMotherHalfSibling;
  const isFormEditable = isSubmitted && !isEditModeInAnySibling;
  const displaySkipButton = !isSubmitted;
  const [buttonClicked, setButtonClicked] = useState(false);
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  const formErrors = validateForm(siblings, nodeRelation);
  const hasErrors = formErrors.siblings.some((siblingErrorObj) =>
    Object.values(siblingErrorObj).some((value) => {
      // Check if the value is an array
      if (Array.isArray(value)) {
        return value.some((childObj) =>
          // For arrays, check if any object within the array has a value that is not undefined
          Object.values(childObj).some((childValue) => childValue !== undefined)
        );
      } else {
        // For primitive values, check if the value is not undefined
        return value !== undefined;
      }
    })
  );

  const handleClose = () => {
    setShowAlert(true);
  }

  const handleSkip = () => {
    setShowAlert(false)
    const isSkipped = true;
    const formAction = "skip";
    const formData = gatherFormData(false, true);
    if(!isResumed){
      sendFormData(JSON.stringify(formData), conversationTone, nodeRelation, "Yes", formAction);
    }
    switch (nodeRelation) {
      case "PS":
        dispatch(setProbandFullSiblingSkipped(true));
        dispatch(setProbandFullSiblingSubmitted(false));
        dispatch(setIsProbandFullSiblingCollapsed(true));
        dispatch(setIsProbandFullSiblingResumed(false));
        break;
      case "PHS":
        dispatch(setProbandHalfSiblingSkipped(true));
        dispatch(setProbandHalfSiblingSubmitted(false));
        dispatch(setIsProbandHalfSiblingCollapsed(true));
        dispatch(setIsPaternalGrandParentCollapsed(true));
        dispatch(setIsProbandHalfSiblingResumed(false));
        break;
      case "PFS":
        dispatch(setFatherFullSiblingSkipped(true));
        dispatch(setFatherFullSiblingSubmitted(false));
        dispatch(setIsFatherFullSiblingCollapsed(true));
        dispatch(setIsFatherFullSiblingResumed(false));
        break;
      case "PFHS":
        dispatch(setFatherHalfSiblingSkipped(true));
        dispatch(setFatherHalfSiblingSubmitted(false));
        dispatch(setIsFatherHalfSiblingCollapsed(true));
        dispatch(setIsFatherHalfSiblingResumed(false));
        break;
      case "PMS":
        dispatch(setMotherFullSiblingSkipped(true));
        dispatch(setMotherFullSiblingSubmitted(false));
        dispatch(setIsMotherFullSiblingCollapsed(true));
        dispatch(setIsMotherFullSiblingResumed(false));
        break;
      case "PMHS":
        dispatch(setMotherHalfSiblingSkipped(true));
        dispatch(setMotherHalfSiblingSubmitted(false));
        dispatch(setIsMotherHalfSiblingCollapsed(true));
        dispatch(setIsMotherHalfSiblingResumed(false));
        break;
      default:
        break;
    }
  };

  const gatherFormData = (checkSubmitted, isFormSkipped) => {
    const siblingsData = siblings.reduce((acc, sibling, index) => {
      acc[`s${index + 1}`] = sibling;
      return acc;
    }, {});

    let isFormSubmitted = false;
    let isEditMode = false;
    let isSkipped = false;

    switch (nodeRelation) {
      case "PS":
        isFormSubmitted = checkSubmitted;
        isEditMode = isEditModeInProbandFullSibling
        isSkipped = isProbandFullSiblingSkipped
        break;
      case "PHS":
        isFormSubmitted = checkSubmitted;
        isEditMode = isEditModeInProbandHalfSibling
        isSkipped = isProbandHalfSiblingSkipped
        break;
      case "PFS":
        isFormSubmitted = checkSubmitted;
        isEditMode = isEditModeInFatherFullSibling
        isSkipped = isFatherFullSiblingSkipped
        break;
      case "PFHS":
        isFormSubmitted = checkSubmitted;
        isEditMode = isEditModeInFatherHalfSibling
        isSkipped = isFatherHalfSiblingSkipped
        break;
      case "PMS":
        isFormSubmitted = checkSubmitted;
        isEditMode = isEditModeInMotherFullSibling
        isSkipped = isMotherFullSiblingSkipped
        break;
      case "PMHS":
        isFormSubmitted = checkSubmitted;
        isEditMode = isEditModeInMotherHalfSibling
        isSkipped = isMotherHalfSiblingSkipped
        break;
      default:
        break;
    }

    return {
      ...siblingsData,
      isFormSubmitted,
      isFormSkipped: isFormSkipped,
      isEditMode: isEditMode,
    };
  };

  useEffect(() => {
    const noderelationSubmissionAndEditStatus = {
      PS: { isSubmitted: isProbandFullSiblingSubmitted, isEditMode: isEditModeInProbandFullSibling },
      PHS: { isSubmitted: isProbandHalfSiblingSubmitted, isEditMode: isEditModeInProbandHalfSibling },
      PFS: { isSubmitted: isFatherFullSiblingSubmitted, isEditMode: isEditModeInFatherFullSibling },
      PFHS: { isSubmitted: isFatherHalfSiblingSubmitted, isEditMode: isEditModeInFatherHalfSibling },
      PMS: { isSubmitted: isMotherFullSiblingSubmitted, isEditMode: isEditModeInMotherFullSibling },
      PMHS: { isSubmitted: isMotherHalfSiblingSubmitted, isEditMode: isEditModeInMotherHalfSibling }
    };
  
    const intervalId = setInterval(() => {
      const nodeStatus = noderelationSubmissionAndEditStatus[nodeRelation];
      if (nodeStatus && (!nodeStatus.isSubmitted || nodeStatus.isEditMode)) {
        handleAutoSave();
      }
    }, 30000);
  
    return () => clearInterval(intervalId);
  }, [
    probandFullSiblings,
    probandHalfSiblings,
    fatherFullSiblings,
    fatherHalfSiblings,
    motherFullSiblings,
    motherHalfSiblings,
    isProbandFullSiblingSubmitted,
    isProbandHalfSiblingSubmitted,
    isFatherFullSiblingSubmitted,
    isFatherHalfSiblingSubmitted,
    isMotherFullSiblingSubmitted,
    isMotherHalfSiblingSubmitted,
    isProbandFullSiblingSkipped,
    isProbandHalfSiblingSkipped,
    isFatherFullSiblingSkipped,
    isFatherHalfSiblingSkipped,
    isMotherFullSiblingSkipped,
    isMotherHalfSiblingSkipped,
    isEditModeInFatherFullSibling,
    isEditModeInFatherHalfSibling,
    isEditModeInMotherFullSibling,
    isEditModeInMotherHalfSibling,
    isEditModeInProbandFullSibling,
    isEditModeInProbandHalfSibling
  ]);
  

  const handleAutoSave = () => {
    const isFormInEditMode =
      isEditModeInProbandFullSibling ||
      isEditModeInProbandHalfSibling ||
      isEditModeInFatherFullSibling ||
      isEditModeInFatherHalfSibling ||
      isEditModeInMotherFullSibling ||
      isEditModeInMotherHalfSibling ? true : false;
    const formData = gatherFormData(isFormInEditMode, isSkipped);
    const jsonFormData = JSON.stringify(formData);
    autoSaveFormData(jsonFormData, nodeRelation);
  };
  const siblingChildRelation = (siblingsWithChildrenAge) => {
    const check = [];
    siblingsWithChildrenAge.map((sibling) =>
      sibling.childrenAges.map((child) => {
        return check.push(Number(sibling.siblingAge) >= Number(child) + 15);
      })
    );
    let validation = true;
    check.forEach((child) => {
      if (!child) return (validation = false);
    });
    return {
      isSiblingAgeValid : validation,
      errors: {
        sibling: validation
          ? null
          : "The age difference between parents and their offspring must be more than 15 years",
      },
    };
  };

  const removeKeyRecursively = (obj, keyToRemove) => {
    if (Array.isArray(obj)) {
      return obj.map(item => removeKeyRecursively(item, keyToRemove));
    } else if (typeof obj === "object" && obj !== null) {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (key !== keyToRemove) {
          acc[key] = removeKeyRecursively(value, keyToRemove);
        }
        return acc;
      }, {});
    }
    return obj;
  };

  const handleFormChange = () => {
    const currentFormState = siblings;
    const filteredDataCurrentFormState = removeKeyRecursively(currentFormState, "isSiblingCollapsed");
    const filteredDataInitialFormState = removeKeyRecursively(initialFormState, "isSiblingCollapsed");
    if(filteredDataCurrentFormState === filteredDataInitialFormState){
      setIsModified(false);
    }
    else{
      setIsModified(!_.isEqual(filteredDataCurrentFormState,filteredDataInitialFormState));
    }
  };
  const handleSubmit = (event) => {
    setContinueButtonClicked(true);
    event.preventDefault();
    let siblingArray = [];
    switch (nodeRelation) {
      case "PFS":
        siblingArray = fatherFullSiblings;
        break;
      case "PFHS":
        siblingArray= fatherHalfSiblings;
        break;
      case "PMS":
        siblingArray= motherFullSiblings
        break;
      case "PMHS":
        siblingArray= motherHalfSiblings;
        break;
      default:
        siblingArray = null;
        break;
    }
    const formErrors = validateForm(siblings, nodeRelation);
    const hasErrors = formErrors.siblings.some((siblingErrorObj) =>
      Object.values(siblingErrorObj).some(value => {
        // Check if the value is an array
        if (Array.isArray(value)) {
          return value.some(childObj => 
            // For arrays, check if any object within the array has a value that is not undefined
            Object.values(childObj).some(childValue => childValue !== undefined)
          );
        } else {
          // For primitive values, check if the value is not undefined
          return value !== undefined;
        }
      })
    );
    if (!hasErrors && !formErrors?.errorSiblingAge) {
      setIsSubmitting(true);
      const formData = gatherFormData(true, false);
      let formAction = isEditMode || savedChoice === "No" || currentChoice === "No"  ? "update" : "continue";
      switch (nodeRelation) {
        case "PS":
          dispatch(setProbandFullSiblingSubmitted(true));
          dispatch(setIsProbandFullSiblingCollapsed(true));
          dispatch(setEditModeInProbandFullSibling(false));
          break;
        case "PHS":
          dispatch(setProbandHalfSiblingSubmitted(true));
          dispatch(setIsProbandHalfSiblingCollapsed(true));
          dispatch(setEditModeInProbandHalfSibling(false));
          if(continueButtonClicked){
             dispatch(setIsPaternalGrandParentCollapsed(true));
             return;
          }
          dispatch(setIsPaternalGrandParentCollapsed(false));
          break;
        case "PFS":
          dispatch(setFatherFullSiblingSubmitted(true));
          dispatch(setIsFatherFullSiblingCollapsed(true));
          dispatch(setEditModeInFatherFullSibling(false));
          break;
        case "PFHS":
          dispatch(setFatherHalfSiblingSubmitted(true));
          dispatch(setIsFatherHalfSiblingCollapsed(true));
          dispatch(setEditModeInFatherHalfSibling(false));
          break;
        case "PMS":
          dispatch(setMotherFullSiblingSubmitted(true));
          dispatch(setIsMotherFullSiblingCollapsed(true));
          dispatch(setEditModeInMotherFullSibling(false));
          break;
        case "PMHS":
          dispatch(setMotherHalfSiblingSubmitted(true));
          dispatch(setIsMotherHalfSiblingCollapsed(true));
          dispatch(setEditModeInMotherHalfSibling(false));
          break;
        default:
          break;
      }
      sendFormData(JSON.stringify(formData), conversationTone, nodeRelation, "Yes", formAction)
        .then(() => {
          if (isEditModeInAnySibling){
            setShowSuccessAlert(true);
            handleToggleCollapse(formAction);
          }
          setIsModified(false);
          setInitialFormState(siblings);
        })
        .catch(() => {
          if (isEditModeInAnySibling){
            alert("An error occurred while submitting data. Please try again.");
          }    
        })
      setIsSubmitting(false);
    } else {
      setErrors(formErrors.siblings);
    }
  };
  useEffect(() => {
    handleFormChange();
  }, [siblings]);

  const handleCancelEdit = () => {
    setShowAlert(false);
    handleCancel(nodeRelation, "cancel");
  };

  const getSiblingHeading = (sibling) => {
    const firstName = sibling.firstName;
    const relationship = sibling.relationship;
    const lastName = sibling.lastName;
    switch (true) {
      case firstName !== "" && relationship !== "":
        return `${identity}'s ${nodeRelationsWithFullSiblings.includes(nodeRelation) ? 'full' : 'half'} ${relationship.toLowerCase()} : ${firstName} ${lastName}`;
      case firstName !== "" && relationship === "":
        return `${identity}'s ${nodeRelationsWithFullSiblings.includes(nodeRelation) ? 'full' : 'half'} sibling : ${firstName} ${lastName}`;
      case firstName === "" && relationship !== "":
        return `${identity}'s ${relationship.toLowerCase()} :`;
      default:
        return `${identity}'s ${nodeRelationsWithFullSiblings.includes(nodeRelation) ? 'full' : 'half'} sibling : `;
    }
  };

//to collapse all the child forms
  const collapseAllSiblingChild = (nodeRelation)=>{
    switch (nodeRelation) {
      case "PS":
        dispatch(toggleAllProbandFullSiblingChildren({isCollapsed: true}));
        break;
      case "PHS":
        dispatch(toggleAllProbandHalfSiblingChildren({isCollapsed: true}));
        break;
      case "PFS":
        dispatch(toggleAllFatherFullSiblingChildren({isCollapsed: true}));
        break;
      case "PFHS":
        dispatch(toggleAllFatherHalfSiblingChildren({isCollapsed: true}));
        break;
      case "PMS":
        dispatch(toggleAllMotherFullSiblingChildren({isCollapsed: true}));
        break;
      case "PMHS":
        dispatch(toggleAllMotherHalfSiblingChildren({isCollapsed: true}));
        break;
      default:
        break;
    }
  }

  const handleToggleCollapse = (formAction) => {
    if( formEditStatus && formAction !== 'update' || formAction === 'continue'){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    dispatch(setEditModeInProbandFullSibling(false));
    dispatch(setEditModeInProbandHalfSibling(false));
    dispatch(setEditModeInFatherFullSibling(false));
    dispatch(setEditModeInFatherHalfSibling(false));
    dispatch(setEditModeInMotherFullSibling(false));
    dispatch(setEditModeInMotherHalfSibling(false));
    collapseAllSiblingChild(nodeRelation);
    collapseAllExcept(nodeRelation, !isCollapsed);
  };

  const addSibling = () => {
    if (hasErrors) {
      setShowFillDetailsAlert(true);
      return;
    }
  
    if (siblingCount > maxSiblingCount) {
      setShowSiblingLimitAlert(true);
      return;
    }
  
    switch (nodeRelation) {
      case "PS":
        dispatch(addProbandFullSibling());
        break;
      case "PHS":
        dispatch(addProbandHalfSibling());
        break;
      case "PFS":
        dispatch(addFatherFullSibling());
        break;
      case "PFHS":
        dispatch(addFatherHalfSibling());
        break;
      case "PMS":
        dispatch(addMotherFullSibling());
        break;
      case "PMHS":
        dispatch(addMotherHalfSibling());
        break;
      default:
        break;
    }

    setSiblingCount((prevCount) => prevCount + 1);
  };

  const handleEditClick = () => {
    if(formEditStatus){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    switch (nodeRelation) {
      case "PS":
        dispatch(setEditModeInProbandFullSibling(true));
        dispatch(setPFSCollapsed());
        break;
      case "PHS":
        dispatch(setEditModeInProbandHalfSibling(true));
        dispatch(setPHSCollapsed());
        break;
      case "PFS":
        dispatch(setEditModeInFatherFullSibling(true));
        dispatch(setFFSCollapsed());
        break;
      case "PFHS":
        dispatch(setEditModeInFatherHalfSibling(true));
        dispatch(setFHSCollapsed());
        break;
      case "PMS":
        dispatch(setEditModeInMotherFullSibling(true));
        dispatch(setMFSCollapsed());
        break;
      case "PMHS":
        dispatch(setEditModeInMotherHalfSibling(true));
        dispatch(setMHSCollapsed());
        break;
      default:
        break;
    }
    collapseAllSiblingChild(nodeRelation);
    collapseAllExcept(nodeRelation, false); 
  }

  useEffect(() => {
    adjustLayout();
  }, [isCollapsed, siblingCount]);

  const handleSaveChangesAlertClose = () => {
    setShowSaveChangesAlert(false);
  };
  const handleShowFillDetailsAlertClose = () => {
    setShowFillDetailsAlert(false);
  };

  const handleShowSiblingLimitAlertClose = () => {
    setShowSiblingLimitAlert(false);
  };

  return (
    <div className={`${!isCollapsed ? "chat-form-container" : "collapsed-chat-form-container"}`}>
      <CustomAccordion
        title={`${identity}'s ${nodeRelationsWithFullSiblings.includes(nodeRelation) ? 'Full' : 'Half'} Sibling`}
        isCollapsed={isCollapsed}
        toggleCollapse={handleToggleCollapse}
        isSubmitted={isSubmitted}
        isSkipped = {isSkipped}
        isHeading={true}
        handleEditClick={handleEditClick}
        nodeRelation={nodeRelation}
        savedChoice={savedChoice}
        currentChoice={currentChoice}
        formEditStatus={isEditMode}
      >
        <Form className={`chat-form`}>
          <div className="siblings-form-container">
            {siblings?.map((sibling, index) => (
              <Sibling
                key={index}
                siblingIndex={index}
                nodeRelation={nodeRelation}
                getSiblingHeading={getSiblingHeading}
                errors={errors[index]}
                setErrors={setErrors}
                setSiblingCount = {setSiblingCount}
                siblingCount={siblingCount}
                hasErrors={hasErrors}
                setSpouseAlert={setSpouseAlert}
                formErrors={formErrors}
                setButtonClicked={setButtonClicked}
                buttonClicked={buttonClicked}
                setContinueButtonClicked={setContinueButtonClicked}
                continueButtonClicked={continueButtonClicked}
              />
            ))}
          </div>
          <div className="chat-form-button">
            {(!isSubmitted || isEditMode) && (
              <Button className="add-sibling-btn"              
               onClick={() => {
                addSibling();
                setButtonClicked(true);
              }}>
                <span>+</span> Add Sibling
              </Button>
            )}
            <div className="continue-skip-btn">
            {(!isSubmitted || isEditMode) && (
              <div className="update-cancel-buttons"> 
                <Button
                  className="form-submit-btn"
                  onClick={handleSubmit}
                  disabled={(isSubmitting || isFormEditable || !isModified) && isEditModeInAnySibling}
                  type="submit"
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {isEditModeInAnySibling ? " Updating..." : " Submitting..."}
                    </>
                  ) : (
                    isEditModeInAnySibling ? "Update" : "Continue"
                  )}
                </Button>

                {isEditMode && (
                  <div>
                    <Button className="cancel-btn" onClick={() => {
                      if (isModified) {
                        setShowAlert(true);
                      } else {
                        handleCancelEdit();
                      }
                    }}>
                      Cancel
                    </Button>
                  </div>
                )
              }
              </div>
            )}
            {displaySkipButton && (
              <Button className="form-skip-btn" onClick={isResumed ? handleSkip : handleClose} disabled={isSubmitted}>
                {isResumed ? "Close" : "Skip"}
              </Button>
            )}
          </div>
          </div>
        </Form>
      </CustomAccordion>
      {showSaveChangesAlert && (
        <UpdateAlert
          title="Unsaved Changes"
          message="Please save your changes in the previous form."
          onConfirm={handleSaveChangesAlertClose}
          confirmButtonText="OK"
        />
      )}
        {showFillDetailsAlert && (
        <UpdateAlert
          title="Fill Mandatory Details"
          message="Please fill the mandatory details first!"
          onConfirm={handleShowFillDetailsAlertClose}
          confirmButtonText="OK"
        />
      )}   
      {spouseAlert && (
        <UpdateAlert
          title="Fill Mandatory Details"
          message="Please fill the mandatory details first!"
          onConfirm={()=>setSpouseAlert(false)}
          confirmButtonText="OK"
        />
      )}      
        {showSiblingLimitAlert && (
        <UpdateAlert
          title="Sib Limit Reached"
          message={`You cannot add more than ${maxSiblingCount} children.`}
          onConfirm={handleShowSiblingLimitAlertClose}
          confirmButtonText="OK"
        />
      )}
      {showSuccessAlert && (
        <UpdateAlert
          title="Success!"
          message="The information has been successfully updated."
          onConfirm={() => setShowSuccessAlert(false)}
        />
      )}
      {showAlert && (
        <CustomAlert
          title={alertTitle}
          message={alertMessage}
          onCancel={() => setShowAlert(false)}
          onConfirm={isEditMode ? handleCancelEdit : handleSkip}
        />
      )}
    </div>
  );
};

export default SiblingForm;
