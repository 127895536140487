import React, { useEffect, useRef, useState } from "react";
import miniIcon from "../../assets/minimizeIcon.svg";
import maxiIcon from "../../assets/maxiIcon.svg";
import checkIcon from "../../assets/checkIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import skippedIcon from "../../assets/skippedIcon.svg";
import editIcon from "../../assets/editIcon.svg";
import noChoiceIcon from "../../assets/noChoiceIcon.svg";
import "../../style.css";
import "./CustomAccordion.css";
import { Button } from "react-bootstrap";
import {
  setIsSkipped as setProbandFullSiblingSkipped,
  setIsResumed as setIsProbandFullSiblingResumed,
} from "../../store/probandFullSiblingFormSlice";
import {
  setIsSkipped as setProbandHalfSiblingSkipped,
  setIsResumed as setIsProbandHalfSiblingResumed,
} from "../../store/probandHalfSiblingFormSlice";
import {
  setIsSkipped as setFatherFullSiblingSkipped,
  setIsResumed as setIsFatherFullSiblingResumed,
} from "../../store/fatherFullSiblingFormSlice";
import {
  setIsSkipped as setFatherHalfSiblingSkipped,
  setIsResumed as setIsFatherHalfSiblingResumed,
} from "../../store/fatherHalfSiblingFormSlice";
import {
  setIsSkipped as setMotherFullSiblingSkipped,
  setIsResumed as setIsMotherFullSiblingResumed,
} from "../../store/motherFullSiblingFormSlice";
import {
  setIsSkipped as setMotherHalfSiblingSkipped,
  setIsResumed as setIsMotherHalfSiblingResumed,
} from "../../store/motherHalfSiblingFormSlice";
import { setIsResumed as setIsProbandPartnerResumed } from "../../store/probandPartnerFormSlice";
import { useDispatch } from "react-redux";
import {CustomAlert} from "../customAlert/CustomAlert";

const CustomAccordion = ({
  title,
  isCollapsed,
  toggleCollapse,
  isSubmitted,
  children,
  isHeading = false,
  isSubSection = false,
  nodeRelation,
  siblingIndex,
  deleteNode,
  headingFlag = "",
  isSkipped = false,
  isChildForm = false,
  handleEditClick,
  isEditMode,
  savedChoice = null,
  currentChoice = null,
  formEditStatus=false
}) => {
  const nodeRelationsWithDeleteButton = ["PS", "PHS", "PFS", "PFHS", "PMS", "PMHS","PPT"];
  const containerClass = isCollapsed && isHeading ? "collapsible-section" : "";
  const headerContainerClass = isHeading && !isCollapsed ? "header-container" : "";
  const formHeaderClass = `${
    isSkipped && !isSubmitted && isCollapsed && !isSubSection
      ? "skipped-form-header"
      : isHeading
      ? "chat-form-header"
      : "chat-form-sub-header"
  }`;
  const formHeadingClass = `${((isSkipped && !isSubmitted && isCollapsed) || savedChoice || currentChoice) ? "skipped-form-heading" : "chat-form-heading"}`;
  const displayCheckIcon = isSubmitted && isCollapsed && isHeading && !isChildForm;
  const displaySkipIcon = isSkipped && !isSubmitted && isCollapsed && isHeading && !isChildForm;
  const displayEditIcon = !isSubSection && isSubmitted && !isEditMode && nodeRelation !== "P";
  const displayDeleteButton = (!isSubmitted || isEditMode) && isChildForm;
  const displayResumeButton = isSkipped && !isSubmitted && isCollapsed && !isSubSection;
  const isDeleteButtonPresentInNodeRelation = !isCollapsed && headingFlag === "" && nodeRelationsWithDeleteButton.includes(nodeRelation);
  const displayNoChoiceIcon = (savedChoice === "No" || currentChoice === "No") && !isSubmitted && isCollapsed && isHeading && !isSkipped;
  const displayAddButton = (savedChoice === "No" || currentChoice === "No") && isCollapsed && !isSubmitted && !isSkipped;
  const displayMandatoryText = isHeading && !isCollapsed && !isSubmitted && !nodeRelationsWithDeleteButton.includes(nodeRelation);

  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const handleResume = () => {
    toggleCollapse();
    switch (nodeRelation) {
      case "PS":
        dispatch(setIsProbandFullSiblingResumed(true));
        break;
      case "PHS":
        dispatch(setIsProbandHalfSiblingResumed(true));
        break;
      case "PFS":
        dispatch(setIsFatherFullSiblingResumed(true));
        break;
      case "PFHS":
        dispatch(setIsFatherHalfSiblingResumed(true));
        break;
      case "PMS":
        dispatch(setIsMotherFullSiblingResumed(true));
        break;
      case "PMHS":
        dispatch(setIsMotherHalfSiblingResumed(true));
        break;
      case "PPT":
        dispatch(setIsProbandPartnerResumed(true));
        break;
      default:
        break;
    }
  };
  const handleDelete = (event) => {
    event.preventDefault();
    setShowAlert(false);
    deleteNode();
  };

  const showAlertOndelete = (event) => {
    event.preventDefault();
    setShowAlert(true);
  };

  const handleAdd = () => {
    toggleCollapse();
  };

  const contentRef = useRef(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    if (contentRef.current) {
      if (!isCollapsed) {
        setHeight(`4000px`);
      } else {
        setHeight("0px");
      }
    }
  }, [isCollapsed, contentRef]);

  return (
    <>
      <div className={containerClass}>
        <div className={headerContainerClass}>
          <div className={formHeaderClass}>
            <div className={formHeadingClass}>
              {displayCheckIcon && <img src={checkIcon} alt="Submitted" className="check-icon" />}
              {displaySkipIcon && <img src={skippedIcon} alt="skipped" className="skip-icon" />}
              {displayNoChoiceIcon && <img src={noChoiceIcon} alt="choice" className="no-choice-icon" />}
              {title}
            </div>
            <div className="minimize-button-section">
              {isDeleteButtonPresentInNodeRelation && (
                <>
                  {displayDeleteButton && (
                    <button className="delete-button" type="button" onClick={(e) => showAlertOndelete(e)}>
                      <img src={deleteIcon} alt="Delete" />
                    </button>
                  )}
                </>
              )}
              {displayAddButton ? (
                <Button className="add-btn" onClick={handleAdd}>
                  Add
                </Button>
              ) : (
                <>
                  {displayResumeButton ? (
                    <Button className="resume-btn" onClick={handleResume}>
                      Resume
                    </Button>
                  ) : (
                    <div className="icon-wrapper">
                      {displayEditIcon && (
                        <button className="toggle-button" type="button" onClick={handleEditClick}>
                          Edit
                        </button>
                      )}
                      <button className="toggle-button" type="button" onClick={toggleCollapse}>
                        {isCollapsed ? <img src={maxiIcon} alt="Expand" /> : <img src={miniIcon} alt="Minimize" />}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {displayMandatoryText && <div className="mandatory-mark">This form is mandatory</div>}
        </div>
        <div
          ref={contentRef}
          style={{ maxHeight: height }}
          className="transition-on-scroll"
        >
          {!isCollapsed && children}
        </div>
      </div>
      {showAlert && (
        <CustomAlert
          title="Are you sure you want to delete?"
          message="The entered information may be lost."
          onConfirm={(e) => handleDelete(e)}
          onCancel={() => setShowAlert(false)}
        />
      )}
    </>
  );
};

export default CustomAccordion;
