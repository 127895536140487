import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./ChatPage.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer, ChatContainer, MessageList, Message, Avatar } from "@chatscope/chat-ui-kit-react";
import Button from "react-bootstrap/Button";
import SaveChatModal from "../../components/modals/SaveChatModal";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal";
import PreviewTreeModal from "../../components/modals/previewTreeModal/PreviewTreeModal";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import { SERVER_HOST } from "../../env";
import { getUserType, getAccessToken, simulateTyping, getTypingSpeed, scrollToElement } from "../utility/utility";
import ChoiceSelector from "../../components/choiceSelector/ChoiceSelector";
import QuestionnaireForm from "../../components/forms/questionnaireForm/QuestionnaireForm";
import aiIcon from "../../assets/aiIcon.svg";
import "../../style.css";
import { updateState } from "../../store/storageSlice";
import previewTreeIcon from "../../assets/previewTreeIcon.svg";
import generateTreeIcon from "../../assets/generateTreeIcon.svg";
import {
  setFormField as updateProbandFields,
  setSubmitted as setIsProbandSubmitted,
  setIsCollapsed as setIsProbandCollapsed,
} from "../../store/probandFormSlice";
import {
  setFather as updateFatherFields,
  setMother as updateMotherFields,
  setIsSubmitted as setIsParentsSubmitted,
  setIsCollapsed as setIsParentsCollapsed,
  setIsEditMode as setEditModeInParents,
} from "../../store/parentsFormSlice";
import {
  updateSiblingField as updateProbandFullSiblingFields,
  setSubmitted as setIsProbandFullSiblingSubmitted,
  setIsCollapsed as setIsProbandFullSiblingCollapsed,
  setIsSkipped as setIsProbandFullSiblingSkipped,
  addSibling as addProbandFullSibling,
  setIsEditMode as setEditModeInProbandFullSibling,
  deleteSibling as deleteProbandFullSibling,
} from "../../store/probandFullSiblingFormSlice";
import {
  updateSiblingField as updateProbandHalfSiblingFields,
  setSubmitted as setIsProbandHalfSiblingSubmitted,
  setIsCollapsed as setIsProbandHalfSiblingCollapsed,
  setIsSkipped as setIsProbandHalfSiblingSkipped,
  addSibling as addProbandHalfSibling,
  setIsEditMode as setEditModeInProbandHalfSibling,
  deleteSibling as deleteProbandHalfSibling,
} from "../../store/probandHalfSiblingFormSlice";
import {
  updatePaternalGrandFather as updatePaternalGrandFatherFields,
  updatePaternalGrandMother as updatePaternalGrandMotherFields,
  setIsSubmitted as setIsPaternalGrandParentsSubmitted,
  setIsCollapsed as setIsPaternalGrandParentCollapsed,
  setIsEditMode as setEditModeInPaternalGrandParents,
} from "../../store/paternalGrandParentFormSlice";
import {
  updateMaternalGrandFather as updateMaternalGrandFatherFields,
  updateMaternalGrandMother as updateMaternalGrandMotherFields,
  setIsSubmitted as setIsMaternalGrandParentsSubmitted,
  setIsCollapsed as setIsMaternalGrandParentCollapsed,
  setIsEditMode as setEditModeInMaternalGrandParents,
} from "../../store/maternalGrandParentFormSlice";
import {
  updateSiblingField as updateFatherFullSiblingFields,
  setSubmitted as setIsFatherFullSiblingSubmitted,
  setIsCollapsed as setIsFatherFullSiblingCollapsed,
  setIsSkipped as setIsFatherFullSiblingSkipped,
  addSibling as addFatherFullSibling,
  setIsEditMode as setEditModeInFatherFullSiblings,
  deleteSibling as deleteFatherFullSibling,
} from "../../store/fatherFullSiblingFormSlice";
import {
  updateSiblingField as updateFatherHalfSiblingFields,
  setSubmitted as setIsFatherHalfSiblingSubmitted,
  setIsCollapsed as setIsFatherHalfSiblingCollapsed,
  setIsSkipped as setIsFatherHalfSiblingSkipped,
  addSibling as addFatherHalfSibling,
  setIsEditMode as setEditModeInFatherHalfSiblings,
  deleteSibling as deleteFatherHalfSibling,
} from "../../store/fatherHalfSiblingFormSlice";
import {
  updateSiblingField as updateMotherFullSiblingFields,
  setSubmitted as setIsMotherFullSiblingSubmiited,
  setIsCollapsed as setIsMotherFullSiblingCollapsed,
  setIsSkipped as setIsMotherFullSiblingSkipped,
  addSibling as addMotherFullSibling,
  setIsEditMode as setEditModeInMotherFullSibling,
  deleteSibling as deleteMotherFullSibling,
} from "../../store/motherFullSiblingFormSlice";
import {
  updateSiblingField as updateMotherHalfSiblingFields,
  setSubmitted as setIsMotherHalfSiblingSubmiited,
  setIsCollapsed as setIsMotherHalfSiblingCollapsed,
  setIsSkipped as setIsMotherHalfSiblingSkipped,
  addSibling as addMotherHalfSibling,
  setIsEditMode as setEditModeInMotherHalfSibling,
  deleteSibling as deleteMotherHalfSibling,
} from "../../store/motherHalfSiblingFormSlice";
import {
  updateField as updateProbandPartnerFields,
  setSubmitted as setIsProbandPartnerSubmitted,
  setIsCollapsed as setIsProbandPartnerCollapsed,
  setIsEditMode as setEditModeInProbandPartner,
} from "../../store/probandPartnerFormSlice";
import { scrollIntoView } from "seamless-scroll-polyfill";
const host = SERVER_HOST;

const initialMessages = [
  {
    message: `Welcome to Manchester Trust. We are here to assist you. Please provide the patient's details below.`,
    option: false,
    nodeRelation: "P",
    choice: null,
    selectedConversationTone: null,
  },
];

function ChatPage() {
  const [modalShow, setModalShow] = useState(false);
  const [showPreviewTreeModal, setShowPreviewTreeModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notify, setNotify] = useState(false);
  const [notifyError, setNotifyError] = useState(true);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [choiceSelected, setChoiceSelected] = useState({
    P: null,
    PP: null,
    PS: null,
    PHS: null,
    PPG: null,
    PMG: null,
    PFS: null,
    PFHS: null,
    PMS: null,
    PMHS: null,
    PPT: null,
  });
  const [isTreeButtonEnabled, setIsTreeButtonEnabled] = useState(false)
  const [messages, setMessages] = useState(initialMessages);
  const { firstName: patientName } = useSelector((state) => state.probandForm);
  const { userId, isNewChat } = useSelector((state) => state.storage);
  const { siblings: probandFullSiblings } = useSelector((state) => state.probandFullSiblingForm);
  const { siblings: probandHalfSiblings } = useSelector((state) => state.probandHalfSiblingForm);
  const { siblings: fatherFullSiblings } = useSelector((state) => state.fatherFullSiblingForm);
  const { siblings: fatherHalfSiblings } = useSelector((state) => state.fatherHalfSiblingForm);
  const { siblings: motherFullSiblings } = useSelector((state) => state.motherFullSiblingForm);
  const { siblings: motherHalfSiblings } = useSelector((state) => state.motherHalfSiblingForm);
  const [isTyping, setIsTyping] = useState(false);
  const scrollRef = useRef(null);
  const isEditModeInProbandParents = useSelector((state) => state.parentsForm.isEditMode);
  const isEditModeInProbandFullSibling = useSelector((state) => state.probandFullSiblingForm.isEditMode);
  const isEditModeInProbandHalfSibling = useSelector((state) => state.probandHalfSiblingForm.isEditMode);
  const isEditModeInFatherFullSibling = useSelector((state) => state.fatherFullSiblingForm.isEditMode);
  const isEditModeInFatherHalfSibling = useSelector((state) => state.fatherHalfSiblingForm.isEditMode);
  const isEditModeInMotherFullSibling = useSelector((state) => state.motherFullSiblingForm.isEditMode);
  const isEditModeInMotherHalfSibling = useSelector((state) => state.motherHalfSiblingForm.isEditMode);
  const isEditModeInPaternalGrandParent = useSelector((state) => state.paternalGrandParentsForm.isEditMode);
  const isEditModeInMaternalGrandParent = useSelector((state) => state.maternalGrandParentsForm.isEditMode);
  const isEditModeInProbandPartner = useSelector((state) => state.probandPartnerForm.isEditMode);
  const formEditStatus = ( isEditModeInProbandParents || 
    isEditModeInProbandFullSibling || 
    isEditModeInProbandHalfSibling || 
    isEditModeInFatherFullSibling ||
    isEditModeInFatherHalfSibling || 
    isEditModeInMotherFullSibling || 
    isEditModeInMotherHalfSibling || 
    isEditModeInPaternalGrandParent || 
    isEditModeInMaternalGrandParent || 
    isEditModeInProbandPartner ) ? true : false;

  const [currentMessage, setCurrentMessage] = useState('');
  const sendFormData = async (
    formData,
    conversationTone,
    nodeRelation = "P",
    choice = "Yes",
    formAction = "continue",
  ) => {
    const apiRequestBody = {
      user_id: userId,
      form_data: formData,
      conversation_tone: conversationTone,
      node_relationship: nodeRelation,
      patient_name: patientName,
      form_action: formAction,
      choice: choice,
    };
    setIsTyping(true);
    setCurrentMessage("");
    try {
      const res = await axios.post(`${host}/conversation?`, apiRequestBody, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      });

      const data = res.data.message;
      if (
        data.conversation_tone !== null &&
        data.conversation_tone === "indirect"
      ) {
        dispatch(updateState({field : "conversationTone", value : "indirect"}));
      }
      simulateTyping(data.message, setCurrentMessage, getTypingSpeed(), () => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: data.message,
            option: data.option,
            nodeRelation: data.node_relation,
          },
        ]);
      setIsTyping(false);
      setIsTreeButtonEnabled(true);
      }); 
    } catch (e) {
      console.error(e);
      setIsTyping(false);
    }
  };

  const isCollapsedProbandForm = useSelector(
    (state) => state.probandForm.isCollapsed
  );
  const isCollapsedParentsForm = useSelector(
    (state) => state.parentsForm.isCollapsed
  );
  const isCollapsedProbandFullSiblingForm = useSelector(
    (state) => state.probandFullSiblingForm.isCollapsed
  );
  const isCollapsedProbandHalfSiblingForm = useSelector(
    (state) => state.probandHalfSiblingForm.isCollapsed
  );
  const isCollapsedPaternalGrandParentsForm = useSelector(
    (state) => state.paternalGrandParentsForm.isCollapsed
  );
  const isCollapsedMaternalGrandParentsForm = useSelector(
    (state) => state.maternalGrandParentsForm.isCollapsed
  );
  const isCollapsedFatherFullSiblingForm = useSelector(
    (state) => state.fatherFullSiblingForm.isCollapsed
  );
  const isCollapsedFatherHalfSiblingForm = useSelector(
    (state) => state.fatherHalfSiblingForm.isCollapsed
  );
  const isCollapsedMotherFullSiblingForm = useSelector(
    (state) => state.motherFullSiblingForm.isCollapsed
  );
  const isCollapsedMotherHalfSiblingForm = useSelector(
    (state) => state.motherHalfSiblingForm.isCollapsed
  );
  const isCollapsedProbandPartnerForm = useSelector(
    (state) => state.probandPartnerForm.isCollapsed
  );
 
function useSiblingCollapsedStates() {
  const isCollapsedProbandFullSiblingFormSiblings = useSelector(
    (state) => state.probandFullSiblingForm.siblings?.map(s => s.isSiblingCollapsed) || []
  );
  const isCollapsedProbandHalfSiblingFormSiblings = useSelector(
    (state) => state.probandHalfSiblingForm.siblings?.map(s => s.isSiblingCollapsed) || []
  );
  const isCollapsedFatherFullSiblingFormSiblings = useSelector(
    (state) => state.fatherFullSiblingForm.siblings?.map(s => s.isSiblingCollapsed) || []
  );
  const isCollapsedFatherHalfSiblingFormSiblings = useSelector(
    (state) => state.fatherHalfSiblingForm.siblings?.map(s => s.isSiblingCollapsed) || []
  );
  const isCollapsedMotherFullSiblingFormSiblings = useSelector(
    (state) => state.motherFullSiblingForm.siblings?.map(s => s.isSiblingCollapsed) || []
  );
  const isCollapsedMotherHalfSiblingFormSiblings = useSelector(
    (state) => state.motherHalfSiblingForm.siblings?.map(s => s.isSiblingCollapsed) || []
  );
  const isCollapsedProbandPartnerFormSiblings = useSelector(
    (state) => state.probandPartnerForm.siblings?.map(s => s.isSiblingCollapsed) || []
  );

  const allCollapsedStates = [
    ...isCollapsedProbandFullSiblingFormSiblings,
    ...isCollapsedProbandHalfSiblingFormSiblings,
    ...isCollapsedFatherFullSiblingFormSiblings,
    ...isCollapsedFatherHalfSiblingFormSiblings,
    ...isCollapsedMotherFullSiblingFormSiblings,
    ...isCollapsedMotherHalfSiblingFormSiblings,
    ...isCollapsedProbandPartnerFormSiblings,
  ];

  return allCollapsedStates;
}
const collapsedStates = useSiblingCollapsedStates();


  const isFormCollapsed = (nodeRelation) => {
    switch (nodeRelation) {
      case "P":
        return isCollapsedProbandForm;
      case "PP":
        return isCollapsedParentsForm;
      case "PS":
        return isCollapsedProbandFullSiblingForm;
      case "PHS":
        return isCollapsedProbandHalfSiblingForm;
      case "PPG":
        return isCollapsedPaternalGrandParentsForm;
      case "PMG":
        return isCollapsedMaternalGrandParentsForm;
      case "PFS":
        return isCollapsedFatherFullSiblingForm;
      case "PFHS":
        return isCollapsedFatherHalfSiblingForm;
      case "PMS":
        return isCollapsedMotherFullSiblingForm;
      case "PMHS":
        return isCollapsedMotherHalfSiblingForm;
      case "PPT":
        return isCollapsedProbandPartnerForm;
      default:
        return false;
    }
  };

  const updateFields = (data, updateFunction, isPartner = false) => {
    Object.keys(data).forEach((field) => {
      if (isPartner) {
        dispatch(updateFunction({ [field]: data[field] }));
      } else {
        dispatch(updateFunction({ field, value: data[field] }));
      }
    });
  };

  const updateSiblings = (siblingsData, addSiblingAction, updateSiblingFieldAction, siblingsList, deleteSiblingAction) => {
    const excludeKeys = ["isFormSubmitted", "isFormSkipped", "isEditMode"];
    const siblings = Object.keys(siblingsData)
      .filter((key) => !excludeKeys.includes(key))
      .map((key) => siblingsData[key]);
    
    siblings.forEach((sibling, index) => {
      if (index >= siblingsList.length) {
        dispatch(addSiblingAction());
      }
      Object.keys(sibling).forEach((field) => {
        dispatch(updateSiblingFieldAction({ index, field, value: sibling[field] }));
      });
    });

    const extraSiblingCount = siblingsList.length - siblings.length;
    if (extraSiblingCount > 0) {
      for (let i = 0; i < extraSiblingCount; i++) {
        dispatch(deleteSiblingAction(siblingsList.length - 1 - i));
      }
    }
  };

  const handleCancel = async(
    nodeRelation,
    formAction,
  ) => {
    const apiRequestBody = {
      user_id: userId,
      node_relationship: nodeRelation,
      form_action: formAction,
    };
    try {
      const res = await axios.post(`${host}/conversation?`, apiRequestBody, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      });

      const data = res.data.form_data[nodeRelation];
      switch (nodeRelation) {
        case "P":
          updateFields(data, updateProbandFields);
          dispatch(setIsProbandSubmitted(true));
          dispatch(setIsProbandCollapsed(true));
          break;
        case "PP":
          dispatch(updateFatherFields(data.father));
          dispatch(updateMotherFields(data.mother));
          dispatch(setIsParentsSubmitted(true));
          dispatch(setIsParentsCollapsed(true));
          dispatch(setEditModeInParents(false));
          break;
        case "PS":
          updateSiblings(data, addProbandFullSibling, updateProbandFullSiblingFields, probandFullSiblings, deleteProbandFullSibling);
          dispatch(setIsProbandFullSiblingSubmitted(true));
          dispatch(setIsProbandFullSiblingSkipped(data["isFormSkipped"]));
          dispatch(setIsProbandFullSiblingCollapsed(true));
          dispatch(setEditModeInProbandFullSibling(false));
          break;
        case "PHS":
          updateSiblings(data, addProbandHalfSibling, updateProbandHalfSiblingFields, probandHalfSiblings, deleteProbandHalfSibling);
          dispatch(setIsProbandHalfSiblingSubmitted(true));
          dispatch(setIsProbandHalfSiblingSkipped(data["isFormSkipped"]));
          dispatch(setIsProbandHalfSiblingCollapsed(true));
          dispatch(setEditModeInProbandHalfSibling(false));
          break;
        case "PMG":
          dispatch(updateMaternalGrandFatherFields(data.grandfather));
          dispatch(updateMaternalGrandMotherFields(data.grandmother));
          dispatch(setIsMaternalGrandParentsSubmitted(true));
          dispatch(setIsMaternalGrandParentCollapsed(true));
          dispatch(setEditModeInMaternalGrandParents(false));
          break;
        case "PPG":
          dispatch(updatePaternalGrandFatherFields(data.grandfather));
          dispatch(updatePaternalGrandMotherFields(data.grandmother));
          dispatch(setIsPaternalGrandParentsSubmitted(true));
          dispatch(setIsPaternalGrandParentCollapsed(true));
          dispatch(setEditModeInPaternalGrandParents(false));
          break;
        case "PFS":
          updateSiblings(data, addFatherFullSibling, updateFatherFullSiblingFields, fatherFullSiblings, deleteFatherFullSibling,);
          dispatch(setIsFatherFullSiblingSubmitted(true));
          dispatch(setIsFatherFullSiblingSkipped(data["isFormSkipped"]));
          dispatch(setIsFatherFullSiblingCollapsed(true));
          dispatch(setEditModeInFatherFullSiblings(false));
          break;
        case "PFHS":
          updateSiblings(data, addFatherHalfSibling, updateFatherHalfSiblingFields, fatherHalfSiblings, deleteFatherHalfSibling);
          dispatch(setIsFatherHalfSiblingSubmitted(true));
          dispatch(setIsFatherHalfSiblingSkipped(data["isFormSkipped"]));
          dispatch(setIsFatherHalfSiblingCollapsed(true));
          dispatch(setEditModeInFatherHalfSiblings(false));
          break;
        case "PMS":
          updateSiblings(data, addMotherFullSibling, updateMotherFullSiblingFields, motherFullSiblings, deleteMotherFullSibling);
          dispatch(setIsMotherFullSiblingSubmiited(true));
          dispatch(setIsMotherFullSiblingSkipped(data["isFormSkipped"]));
          dispatch(setIsMotherFullSiblingCollapsed(true));
          dispatch(setEditModeInMotherFullSibling(false));
          break;
        case "PMHS":
          updateSiblings(data, addMotherHalfSibling, updateMotherHalfSiblingFields, motherHalfSiblings, deleteMotherHalfSibling);
          dispatch(setIsMotherHalfSiblingSubmiited(true));
          dispatch(setIsMotherHalfSiblingSkipped(data["isFormSkipped"]));
          dispatch(setIsMotherHalfSiblingCollapsed(true));
          dispatch(setEditModeInMotherHalfSibling(false));
          break;
        case "PPT":
          updateFields(data, updateProbandPartnerFields, true);
          dispatch(setIsProbandPartnerSubmitted(true));
          dispatch(setIsProbandPartnerCollapsed(true));
          dispatch(setEditModeInProbandPartner(false));
          break;
        default:
          break;
      }

    } catch (e) {
      console.error(e);
    }
  }

  const autoSaveFormData = async (formData, nodeRelation) => {
    setNotifyError(false);
    const apiRequestBody = {
      user_id: userId,
      form_data: formData,
      node_relationship: nodeRelation,
      form_action: "auto_save",
      choice: "Yes",
    };
    try {
      const res = await axios.post(`${host}/conversation?`, apiRequestBody, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      });

      const data = res.data;
      handleApiNotify(data.message);
    } catch (e) {
      console.error(e);
    }
  };

  const generateTree = () => {
    localStorage.setItem("treeData","");
    navigate("/tree");
  };

  const previewTree = () => {
    localStorage.setItem("treeData","");
    setShowPreviewTreeModal(true);
  }

  const handleClosePreviewTreeModal = () => {
    setShowPreviewTreeModal(false);
  };

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };

  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const updateFormsData = (formData) => {
    Object.keys(formData).forEach((key) => {
      switch (key) {
        case "P":
          updateFields(formData[key], updateProbandFields);
          dispatch(setIsProbandSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsProbandCollapsed(true));
          break;
        case "PP":
          dispatch(updateFatherFields(formData[key].father));
          dispatch(updateMotherFields(formData[key].mother));
          dispatch(setIsParentsSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsParentsCollapsed(true));
          break;
        case "PS":
          updateSiblings(formData[key], addProbandFullSibling, updateProbandFullSiblingFields, probandFullSiblings, deleteProbandFullSibling);
          dispatch(setIsProbandFullSiblingSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsProbandFullSiblingSkipped(formData[key]["isFormSkipped"]));
          dispatch(setIsProbandFullSiblingCollapsed(true));
          break;
        case "PHS":
          updateSiblings(formData[key], addProbandHalfSibling, updateProbandHalfSiblingFields, probandHalfSiblings, deleteProbandHalfSibling);
          dispatch(setIsProbandHalfSiblingSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsProbandHalfSiblingSkipped(formData[key]["isFormSkipped"]));
          dispatch(setIsProbandHalfSiblingCollapsed(true));
          break;
        case "PMG":
          dispatch(updateMaternalGrandFatherFields(formData[key].grandfather));
          dispatch(updateMaternalGrandMotherFields(formData[key].grandmother));
          dispatch(setIsMaternalGrandParentsSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsMaternalGrandParentCollapsed(true));
          break;
        case "PPG":
          dispatch(updatePaternalGrandFatherFields(formData[key].grandfather));
          dispatch(updatePaternalGrandMotherFields(formData[key].grandmother));
          dispatch(setIsPaternalGrandParentsSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsPaternalGrandParentCollapsed(true));
          break;
        case "PFS":
          updateSiblings(formData[key], addFatherFullSibling, updateFatherFullSiblingFields, fatherFullSiblings, deleteFatherFullSibling);
          dispatch(setIsFatherFullSiblingSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsFatherFullSiblingSkipped(formData[key]["isFormSkipped"]));
          dispatch(setIsFatherFullSiblingCollapsed(true));
          break;
        case "PFHS":
          updateSiblings(formData[key], addFatherHalfSibling, updateFatherHalfSiblingFields, fatherHalfSiblings, deleteFatherHalfSibling);
          dispatch(setIsFatherHalfSiblingSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsFatherHalfSiblingSkipped(formData[key]["isFormSkipped"]));
          dispatch(setIsFatherHalfSiblingCollapsed(true));
          break;
        case "PMS":
          updateSiblings(formData[key], addMotherFullSibling, updateMotherFullSiblingFields, motherFullSiblings, deleteMotherFullSibling);
          dispatch(setIsMotherFullSiblingSubmiited(formData[key]["isFormSubmitted"]));
          dispatch(setIsMotherFullSiblingSkipped(formData[key]["isFormSkipped"]));
          dispatch(setIsMotherFullSiblingCollapsed(true));
          break;
        case "PMHS":
          updateSiblings(formData[key], addMotherHalfSibling, updateMotherHalfSiblingFields, motherHalfSiblings, deleteMotherHalfSibling);
          dispatch(setIsMotherHalfSiblingSubmiited(formData[key]["isFormSubmitted"]));
          dispatch(setIsMotherHalfSiblingSkipped(formData[key]["isFormSkipped"]));
          dispatch(setIsMotherHalfSiblingCollapsed(true));
          break;
        case "PPT":
          updateFields(formData[key], updateProbandPartnerFields, true);
          dispatch(setIsProbandPartnerSubmitted(formData[key]["isFormSubmitted"]));
          dispatch(setIsProbandPartnerCollapsed(true));
          break;
        default:
          break;
      }
    });
  };

  const topMessageRef = useRef(null);
  const collapseAllExcept = (exclude, isCollapsed = false) => {
    const actions = [
      { action: setIsProbandCollapsed, exclude: "P" },
      { action: setIsParentsCollapsed, exclude: "PP" },
      { action: setIsProbandFullSiblingCollapsed, exclude: "PS" },
      { action: setIsProbandHalfSiblingCollapsed, exclude: "PHS" },
      { action: setIsPaternalGrandParentCollapsed, exclude: "PPG" },
      { action: setIsMaternalGrandParentCollapsed, exclude: "PMG" },
      { action: setIsFatherFullSiblingCollapsed, exclude: "PFS" },
      { action: setIsFatherHalfSiblingCollapsed, exclude: "PFHS" },
      { action: setIsMotherFullSiblingCollapsed, exclude: "PMS" },
      { action: setIsMotherHalfSiblingCollapsed, exclude: "PMHS" },
      { action: setIsProbandPartnerCollapsed, exclude: "PPT" },
    ];

    actions.forEach(({ action, exclude: key }) => {
      if (key === exclude) {
        topMessageRef.current = key;
        dispatch(action(isCollapsed));
      }
      if (key !== exclude) {
        dispatch(action(true));
      }
    });
  };

  useEffect(() => {
    const token = getAccessToken();
    const userType = getUserType(token);
    localStorage.setItem("isFeedbackSubmit", false);

    if (userType === "patient") {
      axios
        .get(`${host}/load?isNewChat=${isNewChat}`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          let chats = [];
          if (isNewChat === 1) {
            chats = [initialMessages[0]];
            setMessages(chats);
          }
          if (isNewChat === 0) {
            chats = [initialMessages[0]];
            let updateChoice = { ...choiceSelected };
            if (data.existing_chat.length !== 0) {
              data.existing_chat.forEach((message) => {
                chats.push({
                  message: message.message,
                  option: message.option,
                  nodeRelation: message.node_relation,
                  choice: message.choice === "Yes" ? "Yes" : message.choice ? "No" : null,
                  selectedConversationTone: data.conversation_tone,
                });
                const nodeRelation = message.nodeRelation;
                if (nodeRelation in updateChoice) {
                  updateChoice[nodeRelation] = message.choice;
                }
              });
            }
            dispatch(updateState({ field: "userId", value: data.user_id }));
            dispatch(updateState({ field: "conversationTone", value: data.conversation_tone }));
            dispatch(updateState({ field: "userName", value: data.user_name }));
            setChoiceSelected(updateChoice);
            setMessages(chats);
            localStorage.removeItem("treeData");
            updateFormsData(data.form_data);
            if(data.existing_chat.length > 0) {
              setIsTreeButtonEnabled(true)
            }
          }
          dispatch(updateState({ field: "isNewChat", value: 0 }));
        })
        .catch((error) => {
          handleApiNotify("An error occurred while loading the data. Please try again later.");
        });
    } else {
      navigate("/");
    }
  }, []);

  const lastMessageRef = useRef(null);
  useMemo(() => {
    if (topMessageRef.current) {
      const topMessage = document.getElementById(topMessageRef.current);
      if (topMessage == lastMessageRef.current) {
        setTimeout(() => {
          scrollIntoView(
            topMessage,
            {
              behavior: "smooth",
              block: "start",
              top: 400,
            },
            {
              duration: 250,
            }
          );
        }, 300);
      } else if (topMessage) {
        scrollIntoView(
          topMessage,
          {
            behavior: "smooth",
            block: "start",
            top: 400,
          },
          {
            duration: 250,
          }
        );
      }
    }
  }, [
    isCollapsedProbandForm,
    isCollapsedParentsForm,
    isCollapsedProbandFullSiblingForm,
    isCollapsedProbandHalfSiblingForm,
    isCollapsedPaternalGrandParentsForm,
    isCollapsedMaternalGrandParentsForm,
    isCollapsedFatherFullSiblingForm,
    isCollapsedFatherHalfSiblingForm,
    isCollapsedMotherFullSiblingForm,
    isCollapsedMotherHalfSiblingForm,
    isCollapsedProbandPartnerForm,
    ...collapsedStates,
  ]);


  useEffect(() => {
    const lastMessageIndex = messages.length - 1;
    const lastMessage = messages[lastMessageIndex];
    const idMap = {
      "PS": "PS",
      "PHS": "PHS",
      "PPG": "PPG",
      "PMG": "PMG",
      "PFS": "PFS",
      "PFHS": "PFHS",
      "PMS": "PMS",
      "PMHS": "PMHS",
      "PPT": "PPT",
      null: "end"
    };

    const targetId = idMap[lastMessage.nodeRelation];
    
    if (targetId) {
      scrollToElement(targetId);
    }
  }, [messages, choiceSelected]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      let lastMessageId = "PPT";

      if (lastMessage.nodeRelation != null) {
        lastMessageId = lastMessage.nodeRelation;
      }
      lastMessageRef.current = document.getElementById(lastMessageId);
    }
  }, [messages, choiceSelected]);
  return (
    <>
      <SaveChatModal show={modalShow} onHide={() => setModalShow(false)} />
      <LogoutButton />
      <div className="chat-page-container">
        <div className="relative-container">
          <h2 className="centered-heading">GeneConnect</h2>
          <MainContainer>
            <ChatContainer className="chatbox-container">
              <MessageList>
                {messages.map((message, i) => {
                  const isChoiceSelected =
                    (message.nodeRelation && choiceSelected[message.nodeRelation]) ||
                    message.choice;
                  return (
                    <React.Fragment key={i}>
                      {i == messages.length - 1} <div ref={scrollRef}></div>
                      {message.message !== undefined && (
                        <>
                          <div
                            id={message.nodeRelation || "end"}
                            ref={topMessageRef}
                          >
                            <Message
                              key={i}
                              model={message}
                              children={<Avatar src={aiIcon} />}
                              className="message-container"
                            />
                          </div>
                          {message.option && (
                            <Message>
                              <Message.CustomContent>
                                <ChoiceSelector
                                  sendFormData={sendFormData}
                                  setChoiceSelected={setChoiceSelected}
                                  nodeRelation={message.nodeRelation}
                                  choiceSelected={message.choice}
                                  selectedConversationTone={message.selectedConversationTone}
                                  messageIndex={i}
                                  collapseAllExcept={collapseAllExcept}
                                  formEditStatus={formEditStatus}
                                />
                              </Message.CustomContent>
                            </Message>
                          )}
                          {!message.option && message.nodeRelation !== null && (
                            <Message
                              className={
                                !isFormCollapsed(message.nodeRelation)
                                  ? "message-wrapper"
                                  : ""
                              }
                            >
                              <Message.CustomContent className="message-box">
                                <QuestionnaireForm
                                  key={i}
                                  sendFormData={sendFormData}
                                  handleCancel = {handleCancel}
                                  autoSaveFormData={autoSaveFormData}
                                  nodeRelation={message.nodeRelation}
                                  collapseAllExcept={collapseAllExcept}
                                  savedChoice = {message.choice}
                                  currentChoice = {choiceSelected[message.nodeRelation]}
                                  formEditStatus = {formEditStatus}
                                />
                              </Message.CustomContent>
                            </Message>
                          )}
                          {isChoiceSelected && (
                            <Message
                              key={`${message.nodeRelation}-${i}`}
                              className={
                                !isFormCollapsed(message.nodeRelation)
                                  ? "message-wrapper"
                                  : ""
                              }
                            >
                              <Message.CustomContent>
                                <QuestionnaireForm
                                  sendFormData={sendFormData}
                                  handleCancel = {handleCancel}
                                  autoSaveFormData={autoSaveFormData}
                                  nodeRelation={message.nodeRelation}
                                  collapseAllExcept={collapseAllExcept}
                                  savedChoice = {message.choice}
                                  currentChoice = {choiceSelected[message.nodeRelation]}
                                  formEditStatus = {formEditStatus}
                                />
                              </Message.CustomContent>
                            </Message>
                          )}
                        </>
                      )}
                      {isTyping && i === messages.length - 1 ? (
                        currentMessage ? (
                          <Message
                            model={{ message: currentMessage }}
                            className="message-container"
                          >
                            <Avatar src={aiIcon} />
                            <Message.CustomContent>
                              <div className="typing-animation">
                                <span>{currentMessage}</span>
                              </div>
                            </Message.CustomContent>
                          </Message>
                        ) : (
                          <Avatar
                            src={aiIcon}
                            className="cs-message message-container"
                          />
                        )
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </MessageList>
            </ChatContainer>
          </MainContainer>
          <div className="generate-tree-preview-btn">
            <Button className="preview-tree-btn" onClick={previewTree} disabled={!isTreeButtonEnabled}>
              <span className="button-content">
              <img className="tree-icon" src={previewTreeIcon} />
              Preview Tree
              </span>
            </Button>
            <Button className="generate-tree-btn" onClick={generateTree} disabled={!isTreeButtonEnabled}>
              <span className="button-content">
              <img className="tree-icon" src={generateTreeIcon} />
              Generate Tree
              </span>
            </Button>
          </div>
          <br></br>
        </div>
      </div>
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        notifyError={notifyError}
        isLoading={false}
      />
      <PreviewTreeModal show={showPreviewTreeModal} onHide={handleClosePreviewTreeModal} />
    </>
  );
}

export default ChatPage;
