import "bootstrap/dist/css/bootstrap.min.css";
import "./LoginForm.css";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginForm = ({ formSubmit,spin, testId }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); 
  };
  return (
    <Form onSubmit={formSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom01">
        <Form.Label className="input-field-label">Username</Form.Label>
          <Form.Control
            name="username"
            required
            type="text"
            defaultValue=""
            className="login-form-input"
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom04"
          className="custom-passkey-group"
        >
          <Form.Label className="input-field-label">Password</Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              name="password"
              required
              type={showPassword ? "text" : "password"}
              defaultValue=""
              className="login-form-input"
            />
            <span
              data-testid="password-toggle-button"
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
          <Form.Control.Feedback type="invalid">
            Incorrect password. Please Enter Correct password and try again.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br></br>
      <div className="d-flex justify-content-center" data-testid={testId}>
        <Button type="submit" id="submit-button" disabled={spin}>
          {spin ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;
