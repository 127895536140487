import { createSlice } from "@reduxjs/toolkit";
import { setChildrenCollapseState } from "./utils/reduxUtils.js";

const initialChildState = {
  firstName: "",
  lastName: "",
  dob: "",
  age: "",
  gender: "",
  isAlive: true,
  isAdopted: null,
  dateOfDeath: null,
  ageOfDeath: null,
  isChildCollapsed: false,
};

const initialSiblingState = {
  firstName: "",
  lastName: "",
  relationship: "",
  dob: "",
  age: "",
  isAlive: true,
  dateOfDeath: null,
  ageOfDeath: null,
  additionalInfo: null,
  isSiblingCollapsed: false,
  isMarried: false,
  partnerFirstName: null,
  partnerLastName: null,
  partnerDob: null,
  partnerAge: null,
  partnerIsAlive: true,
  partnerDateOfDeath: null,
  partnerAgeOfDeath: null,
  partnerAdditionalInfo: null,
  hasChild: null,
  children: [initialChildState],
  isBloodRelated: false,
  isDivorced: false,
  isMarriedCollapsed: true,
  isChildrenCollapsed: true,
};

const initialState = {
  siblings: [initialSiblingState],
  isSubmitted: false,
  isSkipped : false,
  isCollapsed: false,
  isEditMode: false,
  isResumed: false,
};

const motherFullSiblingFormSlice = createSlice({
  name: "motherFullSiblingForm",
  initialState,
  reducers: {
    addSibling(state) {
      state.siblings = [
        ...state.siblings.map((sibling) => ({
          ...sibling,
          isSiblingCollapsed: true,
        })),
        initialSiblingState,
      ];
    },
    deleteSibling(state, action) {
      state.siblings = state.siblings.filter((_, i) => i !== action.payload);
    },
    toggleSiblingCollapse(state, action) {
      const index = action.payload;
      state.siblings = state.siblings.map((sibling, i) => ({
        ...sibling,
        isSiblingCollapsed: i === index ? !sibling.isSiblingCollapsed : true,
      }));
    }, 
    toggleMarriedCollapse(state, action) {
      const index = action.payload;
      state.siblings[index].isMarriedCollapsed = !state.siblings[index].isMarriedCollapsed;
    },
    toggleChildrenCollapse(state, action) {
      const index = action.payload;
      state.siblings[index].isChildrenCollapsed = !state.siblings[index].isChildrenCollapsed;
    },
    updateSiblingField(state, action) {
      const { index, field, value } = action.payload;
      state.siblings[index][field] = value;
    },
    updateChildField(state, action) {
      const { siblingIndex, childIndex, field, value } = action.payload;
      state.siblings[siblingIndex].children[childIndex][field] = value;
    },
    addChild(state, action) {
      const siblingIndex = action.payload;
      state.siblings[siblingIndex].children = [
        ...state.siblings[siblingIndex].children.map((child) => ({
          ...child,
          isChildCollapsed: true,
        })),
        { ...initialChildState },
      ];
    },
    deleteChild(state, action) {
      const { siblingIndex, childIndex } = action.payload;
      state.siblings[siblingIndex].children = state.siblings[siblingIndex].children.filter((_, i) => i !== childIndex);
    },
    toggleChildCollapse(state, action) {
      const { siblingIndex, childIndex } = action.payload;
      state.siblings[siblingIndex].children = state.siblings[siblingIndex].children.map((child, i) => ({
        ...child,
        isChildCollapsed: i === childIndex ? !child.isChildCollapsed : true,
      }));
    },
    setSubmitted(state, action) {
      state.isSubmitted = action.payload;
    },
    setIsCollapsed(state, action){
      state.isCollapsed = action.payload
    },
    setIsEditMode(state, action) {
      state.isEditMode = action.payload;
    },
    setIsSkipped(state, action) {
      state.isSkipped = action.payload;
    },
    setIsResumed(state, action) {
      state.isResumed = action.payload;
    },
    resetForm(state) {
      Object.assign(state, initialState);
    },
    setAllSiblingsCollapsed(state) {
      state.siblings.forEach(sibling => {
        sibling.isSiblingCollapsed = true;
      });
    },
    toggleAllChildrenCollapse(state, action) {
      const { isCollapsed = true } = action.payload;
      setChildrenCollapseState(state, isCollapsed);
    },
  },
});

export const {
  addSibling,
  deleteSibling,
  toggleSiblingCollapse,
  toggleMarriedCollapse,
  toggleChildrenCollapse,
  updateSiblingField,
  updateChildField,
  addChild,
  deleteChild,
  toggleChildCollapse,
  setSubmitted,
  setIsCollapsed,
  setIsEditMode,
  setIsSkipped,
  setIsResumed,
  resetForm,
  setAllSiblingsCollapsed,
  toggleAllChildrenCollapse
} = motherFullSiblingFormSlice.actions;

export default motherFullSiblingFormSlice.reducer;
