import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { updateSiblingField as updateProbandHalfSiblingField } from "../../../store/probandHalfSiblingFormSlice";
import { updateSiblingField as updateFatherHalfSiblingField } from "../../../store/fatherHalfSiblingFormSlice";
import { updateSiblingField as updateMotherHalfSiblingField } from "../../../store/motherHalfSiblingFormSlice";
import "../../../style.css";
import CustomInput from "../../customInput/CustomInput";
import { getMaxNodeCount } from "../../../pages/utility/utility";

export function NameInput({ firstName, lastName, disableFormFeilds, setFirstName, setLastName }) {
  return (
    <>
      <div className="chat-form-name-field element-spacing">
        <Form.Group className="name-input-field">
          <CustomInput
            label="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={disableFormFeilds}
          />
        </Form.Group>
        <Form.Group className="name-input-field">
          <CustomInput
            label="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={disableFormFeilds}
          />
        </Form.Group>
      </div>
    </>
  );
}

export function DobOrAgeInput({ dob, age, disableFormFeilds, setDob, setAge, error = "", nodeRelation }) {
  const showErrorMessage = nodeRelation === "P" && error;
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    if (dob) {
      const calculatedAge = calculateAge(dob);
      if (calculatedAge !== age) {
        setAge(calculatedAge);
      }
    }
  }, [dob, age]);

  return (
    <div className="chat-form-dob-field element-spacing">
      <div className="dob-or-age-container">
        <Form.Group className="dob-field">
          <CustomInput
            label="Date of birth"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            disabled={disableFormFeilds}
            type="date"
          />
        </Form.Group>

        <div className="or-divider">
          <span>Or</span>
        </div>

        <Form.Group className="age-field">
          <CustomInput
            label={nodeRelation === "P" ? "Approximate age *" : "Approximate age"}
            value={age}
            onChange={(e) => setAge(e.target.value)}
            disabled={Boolean(dob) || disableFormFeilds}
            type="number"
            isAgeInput={true}
            error={nodeRelation === "P" ? error : ""}
          />
          {showErrorMessage && <div className="error-message">{error}</div>}
        </Form.Group>
      </div>
    </div>
  );
}

export function GenderInput({
  nodeRelation,
  gender,
  setGender,
  disableFormFeilds,
  siblingIndex = -1,
  childIndex = -1,
  error,
}) {
  return (
    <>
      <Form.Group className="chat-form-gender-field element-spacing">
        <Form.Label className="chat-form-label element-spacing">Gender *</Form.Label>
        <div>
          <label className="custom-radio-button">
            Male
            <input
              type="radio"
              name={`${nodeRelation}-gender-${siblingIndex}-${childIndex}`}
              checked={gender === "male"}
              onChange={() => setGender("male")}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
          <label className="custom-radio-button">
            Female
            <input
              type="radio"
              name={`${nodeRelation}-gender-${siblingIndex}-${childIndex}`}
              checked={gender === "female"}
              onChange={() => setGender("female")}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </Form.Group>
      {error && <div className="error-message">{error}</div>}
    </>
  );
}

export function AliveOrDeceasedInput({
  nodeRelation,
  isAlive,
  disableFormFeilds,
  dateOfDeath,
  ageOfDeath,
  age,
  setIsAlive,
  setDateOfDeath,
  setAgeOfDeath,
  dob,
  siblingIndex = -1,
  childIndex = -1,
}) {

  return (
    <>
      <div className="chat-from-status-field element-spacing">
        <Form.Group className="status-group">
          <Form.Label className="chat-form-label element-spacing">Individual is</Form.Label>
          <div>
            <label className="custom-radio-button">
              Alive
              <input
                type="radio"
                name={`${nodeRelation}-status-${siblingIndex}-${childIndex}`}
                checked={isAlive === true}
                onChange={() => setIsAlive(true)}
                disabled={disableFormFeilds}
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-radio-button">
              Deceased
              <input
                type="radio"
                name={`${nodeRelation}-status-${siblingIndex}-${childIndex}`}
                checked={isAlive === false}
                onChange={() => setIsAlive(false)}
                disabled={disableFormFeilds}
              />
              <span className="checkmark"></span>
            </label>
            <label className="custom-radio-button">
              Don't know
              <input
                type="radio"
                name={`${nodeRelation}-status-${siblingIndex}-${childIndex}`}
                checked={isAlive === "dontKnow"}
                onChange={() => setIsAlive("dontKnow")}
                disabled={disableFormFeilds}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Form.Group>
      </div>
      {!isAlive && (
        <Form.Group className="date-of-death-group">
          <Form.Group className="dod-field">
            <CustomInput
              label="Date of death"
              value={dateOfDeath}
              onChange={(e) => setDateOfDeath(e.target.value)}
              disabled={disableFormFeilds}
              type="date"
            />
          </Form.Group>

          <div className="or-divider">
            <span>Or</span>
          </div>

          <Form.Group className="death-age-field">
            <CustomInput
              label="Age of death"
              value={ageOfDeath}
              onChange={(e) => setAgeOfDeath(e.target.value)}
              disabled={disableFormFeilds}
              type="number"
            />
          </Form.Group>
        </Form.Group>
      )}
    </>
  );
}

export function AdoptionInput({
  nodeRelation,
  isAdopted,
  disableFormFeilds,
  setIsAdopted,
  siblingIndex = -1,
  childIndex = -1,
}) {
  const nodeRelationWithoutAdoptedInOption = ["PS", "PFS", "PMS"];
  return (
    <>
      <Form.Group className="chat-form-adopted-field element-spacing">
        <div>
          {!nodeRelationWithoutAdoptedInOption.includes(nodeRelation) && (
            <label className="custom-radio-button">
              Adopted in
              <input
                type="radio"
                name={`${nodeRelation}-adopted-${siblingIndex}-${childIndex}`}
                checked={isAdopted === "AdoptedIn"}
                onChange={(e) => setIsAdopted("AdoptedIn")}
                disabled={disableFormFeilds}
              />
              <span className="checkmark"></span>
            </label>
          )}
          <label className="custom-radio-button">
            Adopted out
            <input
              type="radio"
              name={`${nodeRelation}-adopted-${siblingIndex}-${childIndex}`}
              checked={isAdopted === "AdoptedOut"}
              onChange={(e) => setIsAdopted("AdoptedOut")}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
          <label className="custom-radio-button">
            Don't know
            <input
              type="radio"
              name={`${nodeRelation}-adopted-${siblingIndex}-${childIndex}`}
              checked={isAdopted === "DontKnow"}
              onChange={(e) => setIsAdopted("DontKnow")}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </Form.Group>
    </>
  );
}

export function AdditionalInfoInput({ disableFormFeilds, additionalInfo, setAdditionalInfo }) {
  return (
    <>
      <Form.Group className="element-spacing">
        <CustomInput
          label="Clinical or medical history"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          disabled={disableFormFeilds}
          isAdditionalInfo = {true}
        />
      </Form.Group>
    </>
  );
}

export function PregnancyLossInput({
  nodeRelation,
  entity,
  setEntity,
  disableFormFeilds,
  errors,
  clearFieldError,
  setErrors,
}) {
  const maxPregnancyLossCount = getMaxNodeCount();
  const handleNumberOfLossesChange = (e) => {
    const count = parseInt(e.target.value, 10);
    if (Number.isFinite(count) && count > 0) {
      const newState = {
        ...entity,
        numberOfLosses: count,
        lossCauses: new Array(count).fill(""),
      };
      setEntity(newState);
      clearFieldError("numberOfLosses", errors, setErrors);
    } else {
      const newState = {
        ...entity,
        numberOfLosses: 0,
        lossCauses: [],
      };
      setEntity(newState);
      clearFieldError("numberOfLosses", errors, setErrors);
    }
  };

  const handleLossCauseChange = (index, value) => {
    const updatedCauses = [...entity.lossCauses];
    updatedCauses[index] = value;
    const newState = {
      ...entity,
      lossCauses: updatedCauses,
    };
    setEntity(newState);
    clearFieldError(`lossCause${index}`, errors, setErrors);
  };

  const getOrdinalSuffix = (n) => {
    const suffixArray = ["th", "st", "nd", "rd"];
    const rem = n % 100;
    return n + (suffixArray[(rem - 20) % 10] || suffixArray[rem] || suffixArray[0]);
  };

  return (
    <>
      <div className="pregnancy-loss-input">
        <Form.Group className="element-spacing pregnancy-loss-checkbox">
          <label className="custom-radio-button">
            Is there a history of pregnancy loss ?
            <input
              type="checkbox"
              name={`${nodeRelation}-pregnancy-loss`}
              checked={entity.pregnancyLoss}
              onChange={(e) => {
                const newState = {
                  ...entity,
                  pregnancyLoss: e.target.checked,
                };
                setEntity(newState);
              }}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
        </Form.Group>
        {entity.pregnancyLoss && (
          <Form.Group className="element-spacing">
            <CustomInput
              label="No. of pragenancy loses"
              type="number"
              value={entity.numberOfLosses > 0 ? entity.numberOfLosses : ""}
              onChange={handleNumberOfLossesChange}
              disabled={disableFormFeilds}
              min="1"
              maxInputLimit={maxPregnancyLossCount}
              error={errors.numberOfLosses}
            />
            {errors.numberOfLosses && <div className="error-message">{errors.numberOfLosses}</div>}
          </Form.Group>
        )}
      </div>
      {entity.pregnancyLoss &&
        entity.lossCauses.map((cause, index) => (
          <Form.Group key={index} className="element-spacing">
            <Form.Label className="chat-form-label">
              What was the cause of {getOrdinalSuffix(index + 1)} pregnancy loss?
            </Form.Label>
            <div className="element-spacing">
              <label className="custom-radio-button">
                Abortion
                <input
                  type="radio"
                  name={`${nodeRelation}-loss-cause-${index}`}
                  value="Abortion"
                  checked={cause === "Abortion"}
                  onChange={(e) => handleLossCauseChange(index, e.target.value)}
                  disabled={disableFormFeilds}
                />
                <span className="checkmark"></span>
              </label>
              <label className="custom-radio-button">
                Stillbirth
                <input
                  type="radio"
                  name={`${nodeRelation}-loss-cause-${index}`}
                  value="StillBirth"
                  checked={cause === "StillBirth"}
                  onChange={(e) => handleLossCauseChange(index, e.target.value)}
                  disabled={disableFormFeilds}
                />
                <span className="checkmark"></span>
              </label>
              <label className="custom-radio-button">
                Miscarriage
                <input
                  type="radio"
                  name={`${nodeRelation}-loss-cause-${index}`}
                  value="Miscarriage"
                  checked={cause === "Miscarriage"}
                  onChange={(e) => handleLossCauseChange(index, e.target.value)}
                  disabled={disableFormFeilds}
                />
                <span className="checkmark"></span>
              </label>
              <label className="custom-radio-button">
                Don't know
                <input
                  type="radio"
                  name={`${nodeRelation}-loss-cause-${index}`}
                  value="dontKnow"
                  checked={cause === "dontKnow"}
                  onChange={(e) => handleLossCauseChange(index, e.target.value)}
                  disabled={disableFormFeilds}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {errors[`lossCause${index}`] && <div className="error-message">{errors[`lossCause${index}`]}</div>}
          </Form.Group>
        ))}
    </>
  );
}

export function DivorcedAndRelatedByBloodInput({
  nodeRelation,
  isBloodRelated,
  setIsBloodRelated,
  isDivorced,
  setIsDivorced,
  disableFormFeilds,
}) {
  return (
    <>
      <div className="chat-form-component-spacing">
        <Form.Group>
          <label className="custom-radio-button">
            Click here if related by blood ?
            <input
              type="checkbox"
              name="isBloodRelated"
              checked={isBloodRelated}
              onChange={(e) => setIsBloodRelated(e.target.checked)}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
        </Form.Group>
        <Form.Group>
          <label className="custom-radio-button">
           Click here if separated ?
            <input
              type="checkbox"
              name="isDivorced"
              checked={isDivorced}
              onChange={(e) => setIsDivorced(e.target.checked)}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
        </Form.Group>
      </div>
    </>
  );
}

export function SelectCommonParentInput({
  nodeRelation,
  siblingIndex,
  commonParent,
  handleCommonParentChange,
  disableFormFeilds,
  error,
  clearFieldError,
}) {
  const dispatch = useDispatch();

  const resetOtherParentName = () => {
    switch (nodeRelation) {
      case "PHS":
        dispatch(updateProbandHalfSiblingField({ index: siblingIndex, field: "otherParentName", value: "" }));
        break;
      case "PFHS":
        dispatch(updateFatherHalfSiblingField({ index: siblingIndex, field: "otherParentName", value: "" }));
        break;
      case "PMHS":
        dispatch(updateMotherHalfSiblingField({ index: siblingIndex, field: "otherParentName", value: "" }));
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Form.Group className="common-parent-input element-spacing">
        <Form.Label className="chat-form-label element-spacing">Common Parent *</Form.Label>
        <div>
          <label className="custom-radio-button">
            Mother
            <input
              type="radio"
              name={`${nodeRelation}-common-parent-${siblingIndex}`}
              value="Mother"
              checked={commonParent === "Mother"}
              onChange={(e) => {
                handleCommonParentChange(e.target.value);
                clearFieldError("commonParent");
                resetOtherParentName();
              }}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
          <label className="custom-radio-button">
            Father
            <input
              type="radio"
              name={`${nodeRelation}-common-parent-${siblingIndex}`}
              value="Father"
              checked={commonParent === "Father"}
              onChange={(e) => {
                handleCommonParentChange(e.target.value);
                clearFieldError("commonParent");
                resetOtherParentName(); 
              }}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </Form.Group>
      {error && <div className="error-message">{error}</div>}
    </>
  );
}

export function CommonParentNameInput({ siblingIndex, commonParent, disableFormFeilds, nodeRelation }) {
  const probandHalfSiblings = useSelector((state) => state.probandHalfSiblingForm.siblings[siblingIndex]);
  const fatherHalfSiblings = useSelector((state) => state.fatherHalfSiblingForm.siblings[siblingIndex]);
  const motherHalfSiblings = useSelector((state) => state.motherHalfSiblingForm.siblings[siblingIndex]);

  const getSiblingData = () => {
    switch (nodeRelation) {
      case "PHS":
        return { sibling: probandHalfSiblings };
      case "PFHS":
        return { sibling: fatherHalfSiblings };
      case "PMHS":
        return { sibling: motherHalfSiblings };
      default:
        return { sibling: null };
    }
  };

  const { sibling } = getSiblingData();

  const dispatch = useDispatch();

  const otherParent = commonParent === "Mother" ? "Father" : "Mother";

  const handleUpdateField = (field, value) => {
    switch (nodeRelation) {
      case "PHS":
        dispatch(updateProbandHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PFHS":
        dispatch(updateFatherHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PMHS":
        dispatch(updateMotherHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      default:
        break;
    }
  };

  return (
    <div className="chat-form-name-field">
      <Form.Group className="other-parent-name-input">
        <CustomInput
          label={`${otherParent} Name`}
          type="text"
          value={sibling.otherParentName || ""}
          onChange={(event) => handleUpdateField("otherParentName", event.target.value)}
          disabled={disableFormFeilds}
        />
      </Form.Group>
      <Form.Group className="checkbox-input">
        <label className="custom-radio-button">
          Don't know
          <input
            type="checkbox"
            name="otherParentName"
            value="Dont Know"
            checked={sibling.otherParentName === "Dont Know"}
            onChange={(e) => handleUpdateField("otherParentName", e.target.checked ? "Dont Know" : "")}
            disabled={disableFormFeilds}
          />
          <span className="checkmark"></span>
        </label>
      </Form.Group>
    </div>
  );
}

export function SiblingRelationInput({
  nodeRelation,
  siblingIndex,
  relationship,
  handleRelationshipChange,
  disableFormFeilds,
  error,
}) {
  return (
    <>
      <Form.Group className="sibling-relation-input element-spacing">
        <Form.Label className="chat-form-label element-spacing">Relationship *</Form.Label>
        <div>
          <label className="custom-radio-button">
            Brother
            <input
              type="radio"
              name={`${nodeRelation}-relationship-${siblingIndex}`}
              value="Brother"
              checked={relationship === "Brother"}
              onChange={(e) => handleRelationshipChange(e.target.value)}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
          <label className="custom-radio-button">
            Sister
            <input
              type="radio"
              name={`${nodeRelation}-relationship-${siblingIndex}`}
              value="Sister"
              checked={relationship === "Sister"}
              onChange={(e) => handleRelationshipChange(e.target.value)}
              disabled={disableFormFeilds}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </Form.Group>
      {error && <div className="error-message">{error}</div>}
    </>
  );
}
