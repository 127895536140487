import { createSlice } from "@reduxjs/toolkit";
import { setChildrenCollapseState } from "./utils/reduxUtils.js";

const initialChildState = {
  firstName: "",
  lastName: "",
  dob: "",
  age: "",
  gender: "",
  isAlive: true,
  isAdopted: "",
  dateOfDeath: null,
  ageOfDeath: null,
  isChildCollapsed: false,
};

const initialPartnerState = {
  firstName: "",
  lastName: "",
  dob: "",
  age: "",
  isAlive: true,
  isAdopted: null,
  dateOfDeath: null,
  ageOfDeath: null,
  additionalInfo: null,
  hasChild: false,
  children: [initialChildState],
  isBloodRelated: false,
  isDivorced: false,
  pregnancyLoss: false,
  numberOfLosses: 0,
  lossCauses: [],
};

const initialState = {
    partner : {...initialPartnerState},
    isSubmitted: false,
    isCollapsed: false,
    isEditMode: false,
    isSkipped : false,
    isResumed: false,
}

const probandPartnerFormSlice = createSlice({
  name: "probandPartnerForm",
  initialState,
  reducers: {
    updateField: (state, action) => {
      state.partner = {...state.partner, ...action.payload};
    },
    updateChildField: (state, action) => {
      const { childIndex, field, value } = action.payload;
      state.partner.children[childIndex][field] = value;
    },
    addChild: (state) => {
      state.partner.children = state.partner.children.map((child) => ({
        ...child,
        isChildCollapsed: true,
      }));
      state.partner.children.push({ ...initialChildState });
    },
    deleteChild: (state, action) => {
      const { childIndex } = action.payload;
      state.partner.children.splice(childIndex, 1);
    },
    toggleChildCollapse: (state, action) => {
      const { childIndex } = action.payload;
      state.partner.children = state.partner.children.map((child, i) => ({
        ...child,
        isChildCollapsed: i === childIndex ? !child.isChildCollapsed : true,
      }));
    },
    setSubmitted: (state, action) => {
      state.isSubmitted = action.payload;
    },
    setIsCollapsed(state, action){
      state.isCollapsed = action.payload
    },
    resetForm(state) {
      Object.assign(state, initialState);
    },
    setIsEditMode(state, action) {  
      state.isEditMode = action.payload;
    },
    setIsSkipped(state, action) {
      state.isSkipped = action.payload;
    },
    setIsResumed(state, action) {
      state.isResumed = action.payload;
    },
    toggleAllChildrenCollapse(state, action) {
      const { isCollapsed = true } = action.payload;
      console.log("inside pp", isCollapsed)
      setChildrenCollapseState(state, isCollapsed);
    },
  },
});

export const { updateField, updateChildField, addChild, deleteChild, toggleChildCollapse, setSubmitted, setIsCollapsed, resetForm, setIsEditMode, setIsSkipped, setIsResumed, toggleAllChildrenCollapse } =
  probandPartnerFormSlice.actions;

export default probandPartnerFormSlice.reducer;

