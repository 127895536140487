import { createSlice } from "@reduxjs/toolkit";
import { setChildrenCollapseState } from "./utils/reduxUtils.js";

const initialHalfSiblingState = {
  firstName: "",
  lastName: "",
  relationship: "",
  dob: "",
  age: "",
  isAlive: true,
  dateOfDeath: null,
  ageOfDeath: null,
  isAdopted: null,
  commonParent: null,
  otherParentName: "",
  additionalInfo: null,
  isSiblingCollapsed: false,
};

const initialState = {
  siblings: [initialHalfSiblingState],
  isSubmitted: false,
  isSkipped : false,
  isCollapsed: false,
  isEditMode: false,
  isResumed: false,
};

const probandHalfSiblingFormSlice = createSlice({
  name: "probandHalfSiblingForm",
  initialState,
  reducers: {
    addSibling(state) {
      state.siblings = [
        ...state.siblings.map((sibling) => ({
          ...sibling,
          isSiblingCollapsed: true,
        })),
        initialHalfSiblingState,
      ];
    },
    deleteSibling(state, action) {
      state.siblings = state.siblings.filter((_, i) => i !== action.payload);
    },
    toggleSiblingCollapse(state, action) {
      const index = action.payload;
      state.siblings = state.siblings.map((sibling, i) => ({
        ...sibling,
        isSiblingCollapsed: i === index ? !sibling.isSiblingCollapsed : true,
      }));
    }, 
    updateSiblingField(state, action) {
      const { index, field, value } = action.payload;
      state.siblings[index][field] = value;
    },
    setSubmitted(state, action) {
      state.isSubmitted = action.payload;
    },
    setIsCollapsed(state, action){
      state.isCollapsed = action.payload
    },
    setIsEditMode(state, action) {
      state.isEditMode = action.payload;
    },
    setIsSkipped(state, action) {
      state.isSkipped = action.payload;
    },
    setIsResumed(state, action) {
      state.isResumed = action.payload;
    },
    resetForm(state) {
      Object.assign(state, initialState);
    },
    setAllSiblingsCollapsed(state) {
      state.siblings.forEach(sibling => {
        sibling.isSiblingCollapsed = true;
      });
    },
    toggleAllChildrenCollapse(state, action) {
      const { isCollapsed = true } = action.payload;
      setChildrenCollapseState(state, isCollapsed);
    },
  },
});

export const {
  addSibling,
  deleteSibling,
  toggleSiblingCollapse,
  updateSiblingField,
  setSubmitted,
  setIsCollapsed,
  setIsEditMode,
  setIsSkipped,
  resetForm,
  setIsResumed,
  setAllSiblingsCollapsed,
  toggleAllChildrenCollapse
} = probandHalfSiblingFormSlice.actions;

export default probandHalfSiblingFormSlice.reducer;
