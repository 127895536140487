export const setChildrenCollapseState = (state, isCollapsed) => {
  if (state.siblings) {
    state.siblings?.forEach((sibling) => {
      sibling.children = sibling?.children?.map((child) => ({
        ...child,
        isChildCollapsed: isCollapsed,
      }));
    });
  }
};

